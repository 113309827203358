"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateUtils = void 0;
var DateUtils;
(function (DateUtils) {
    const YEAR_MILIS = 31556952000;
    function fromAqopiDate(aqopiDate, required = false) {
        if (!aqopiDate) {
            if (required) {
                throw Error('');
            }
            return;
        }
        const splitDate = aqopiDate.split('-');
        const date = new Date();
        date.setUTCFullYear(Number(splitDate[2]), Number(splitDate[1]) - 1, Number(splitDate[0]));
        date.setUTCHours(0, 0, 0, 0);
        return date;
    }
    DateUtils.fromAqopiDate = fromAqopiDate;
    function fromAqopiDateRequired(aqopiDate) {
        const date = fromAqopiDate(aqopiDate, true);
        if (!date)
            throw Error('Invalid date');
        return date;
    }
    DateUtils.fromAqopiDateRequired = fromAqopiDateRequired;
    function toDateTimeString(date) {
        if (!date)
            return undefined;
        return date.toISOString().replace(/\..*/, '');
    }
    DateUtils.toDateTimeString = toDateTimeString;
})(DateUtils = exports.DateUtils || (exports.DateUtils = {}));
