"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapDienstbetrekking = exports.mapDienstbetrekkingen = void 0;
const date_1 = require("../../utils/date");
const mapping_1 = require("../../utils/mapping");
const clone_1 = require("../../utils/clone");
const milliSecondsInDay = 1000 * 60 * 60 * 24;
function mapDienstbetrekkingen(defaults, aowDates, summary) {
    var _a, _b, _c, _d;
    const werkgevers = (_d = (_c = (_b = (_a = summary.inkomens) === null || _a === void 0 ? void 0 : _a.werkgevers) === null || _b === void 0 ? void 0 : _b.val) === null || _c === void 0 ? void 0 : _c.filter(({ indIsHuidigeWerkgever }) => indIsHuidigeWerkgever)) !== null && _d !== void 0 ? _d : [];
    if (!werkgevers.length) {
        return [];
    }
    if (!mapping_1.MappingUtils.checkDefaults(defaults, werkgevers, 'dienstbetrekkingen')) {
        return [];
    }
    return werkgevers.map((werkgever, idx) => mapDienstbetrekking(defaults[idx], werkgever, aowDates.client));
}
exports.mapDienstbetrekkingen = mapDienstbetrekkingen;
function mapDienstbetrekking(defaults, employer, aowDate) {
    var _a, _b, _c, _d, _e, _f;
    const slips = employer.slips;
    const defaultsBrutoJaarsalaris = (0, clone_1.clone)(defaults.salarisComponenten.brutoJaarsalaris);
    const defaultsAutoBijdrage = (0, clone_1.clone)(defaults.salarisComponenten.autoBijdrage);
    const defaultsAutoBijtelling = (0, clone_1.clone)(defaults.salarisComponenten.autobijtelling);
    const dienstbetrekking = Object.assign(Object.assign({}, (0, clone_1.clone)(defaults)), { naam: employer.werkgever });
    if (slips && slips.length) {
        const endDate = (_a = date_1.DateUtils.fromAqopiDate(slips.at(0).eindDatum)) !== null && _a !== void 0 ? _a : new Date();
        let startDate = date_1.DateUtils.fromAqopiDate((_b = slips.at(-1)) === null || _b === void 0 ? void 0 : _b.startDatum);
        let yearlySalary = 0;
        const lastSlipEndDateString = date_1.DateUtils.toDateTimeString(endDate);
        if (lastSlipEndDateString && !employer.indIsHuidigeWerkgever) {
            dienstbetrekking.duur.einddatum = lastSlipEndDateString;
        }
        // Use the Omniplan calculated AOW Date instead (already part of the defaults)
        // else if (aowDate) {
        // 	dienstbetrekking.duur.einddatum = DateUtils.toDateTimeString(aowDate)!;
        // }
        const firstSlipStartDateString = date_1.DateUtils.toDateTimeString(startDate);
        if (firstSlipStartDateString) {
            dienstbetrekking.duur.ingangsdatum = firstSlipStartDateString;
        }
        // Use the last data on auto bijdrage/bijtelling to determine the yearly totals
        const yearlyAutoBijdrage = Math.floor(((_d = (_c = slips.at(0)) === null || _c === void 0 ? void 0 : _c.eigenBijdrageAuto) !== null && _d !== void 0 ? _d : 0) * 12);
        const yearlyAutoBijtelling = Math.floor(((_f = (_e = slips.at(0)) === null || _e === void 0 ? void 0 : _e.fiscaleBijtellingAuto) !== null && _f !== void 0 ? _f : 0) * 12);
        if (startDate) {
            if (!lessThanAYearInBetween(startDate, endDate)) {
                // Use last 12 months to calculate yearly income
                for (let idx = 0, periodStartDate = date_1.DateUtils.fromAqopiDate(slips[idx].startDatum); periodStartDate && lessThanAYearInBetween(periodStartDate, endDate); idx++, periodStartDate = date_1.DateUtils.fromAqopiDate(slips[idx].startDatum)) {
                    yearlySalary += slips[idx].brutoSalaris;
                }
            }
            else {
                // Use the average salary * 12
                yearlySalary = employer.gemiddeldSalaris * 12;
            }
        }
        yearlySalary = Math.floor(yearlySalary);
        dienstbetrekking.salarisComponenten.brutoJaarsalaris.periodieken = [
            createPeriodiekBedrag(yearlySalary, defaultsBrutoJaarsalaris.periodieken[0], dienstbetrekking.duur.ingangsdatum, dienstbetrekking.duur.einddatum),
        ];
        dienstbetrekking.salarisComponenten.autoBijdrage.periodieken = [
            createPeriodiekBedrag(yearlyAutoBijdrage, defaultsAutoBijdrage.periodieken[0], dienstbetrekking.duur.ingangsdatum, dienstbetrekking.duur.einddatum),
        ];
        dienstbetrekking.salarisComponenten.autobijtelling.periodieken = [
            createPeriodiekBedrag(yearlyAutoBijtelling, defaultsAutoBijtelling.periodieken[0], dienstbetrekking.duur.ingangsdatum, dienstbetrekking.duur.einddatum),
        ];
    }
    return dienstbetrekking;
}
exports.mapDienstbetrekking = mapDienstbetrekking;
function createPeriodiekBedrag(amount, defaults, startDateStr, endDateStr) {
    return Object.assign(Object.assign({}, defaults), { bedrag: {
            waarde: amount !== null && amount !== void 0 ? amount : defaults.bedrag,
        }, einddatum: endDateStr !== null && endDateStr !== void 0 ? endDateStr : defaults.einddatum, ingangsdatum: startDateStr !== null && startDateStr !== void 0 ? startDateStr : defaults.ingangsdatum });
}
function lessThanAYearInBetween(startDate, endDate) {
    var diffInDays = Math.round((+endDate - +startDate) / milliSecondsInDay);
    return diffInDays <= 365;
}
