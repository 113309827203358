"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiscaalRegime = exports.LijfrenteType = void 0;
var LijfrenteType;
(function (LijfrenteType) {
    LijfrenteType["Verzekerd"] = "Verzekerd";
    LijfrenteType["Bancair"] = "Bancair";
})(LijfrenteType = exports.LijfrenteType || (exports.LijfrenteType = {}));
var FiscaalRegime;
(function (FiscaalRegime) {
    FiscaalRegime["PreBredeHerwaardering"] = "PreBredeHerwaardering";
    FiscaalRegime["BredeHerwaardering"] = "BredeHerwaardering";
    FiscaalRegime["IB2001GeslotenVoor1Januari2006"] = "IB2001GeslotenVoor1Januari2006";
    FiscaalRegime["IB2001GeslotenTussen1Januari2006En1Januari2014"] = "IB2001GeslotenTussen1Januari2006En1Januari2014";
    FiscaalRegime["GeslotenVanaf1Januari2014"] = "GeslotenVanaf1Januari2014";
    FiscaalRegime["Stamrecht"] = "Stamrecht";
})(FiscaalRegime = exports.FiscaalRegime || (exports.FiscaalRegime = {}));
