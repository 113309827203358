"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLeningOfType = exports.mapHypothecaireLeningdelen = exports.aqopiToOmniPlanOnderwerpMap = void 0;
const aqopi_types_1 = require("@iwize-aqopi/aqopi-types");
const date_1 = require("../../utils/date");
var fromAqopiDate = date_1.DateUtils.fromAqopiDate;
const map_utils_1 = require("../map-utils");
const clone_1 = require("../../utils/clone");
const MortgagePartType = aqopi_types_1.Aqopi.Summary.MortgagePartType;
exports.aqopiToOmniPlanOnderwerpMap = {
    [MortgagePartType.Aflosvrij]: 'AflossingsvrijeHypotheek',
    [MortgagePartType.Annuiteit]: 'AnnuiteitenHypotheek',
    [MortgagePartType.Bankspaarhypotheek]: 'BankspaarHypotheek',
    [MortgagePartType.Belegging]: 'BeleggingsHypotheek',
    [MortgagePartType.Hybride]: null,
    [MortgagePartType.Krediet]: 'KredietHypotheek',
    [MortgagePartType.Leven]: 'LevenHypotheek',
    [MortgagePartType.Lineair]: 'LineaireHypotheek',
    [MortgagePartType.Spaar]: 'SpaarHypotheek',
};
function mapHypothecaireLeningdelen(mortgageParts, taxTermEndDateCollection, clientId, leningdeelDefaults) {
    if (leningdeelDefaults.length < mortgageParts.length) {
        throw `The defaults for Leningdelen appear incomplete. Defaults: [${leningdeelDefaults
            .map(({ $type }) => $type)
            .join(',')}] - Provided: [${mortgageParts.map(({ label }) => label).join(',')}]`;
    }
    const mappedOnderwerpen = mortgageParts.map((mortgagePart) => {
        const omniPlanMapping = exports.aqopiToOmniPlanOnderwerpMap[mortgagePart.soortHypotheekAfgeleid];
        if (!omniPlanMapping) {
            return undefined;
        }
        const defaultHypotheekDeel = getDefaultsForOnderwerp(leningdeelDefaults, omniPlanMapping);
        return defaultHypotheekDeel && mapHypothecaireLeningDeel(mortgagePart, defaultHypotheekDeel, taxTermEndDateCollection, clientId);
    });
    return mappedOnderwerpen.filter((v) => !!v);
}
exports.mapHypothecaireLeningdelen = mapHypothecaireLeningdelen;
function getDefaultsForOnderwerp(hypotheekDelen, type) {
    return hypotheekDelen.find((hypotheekDeel) => hypotheekDeel.$type === type);
}
function mapHypothecaireLeningDeel(summaryMortagePart, defaults, taxTermEndDateCollection, clientId) {
    var _a, _b, _c;
    const startDate = date_1.DateUtils.toDateTimeString(fromAqopiDate(summaryMortagePart.begindatumBerekend));
    const endDate = date_1.DateUtils.toDateTimeString(fromAqopiDate(summaryMortagePart.einddatumBerekend));
    const toewijzing = (0, map_utils_1.getToewijzing)(summaryMortagePart, clientId);
    const taxTermEndDate = taxTermEndDateCollection[toewijzing];
    defaults = (0, clone_1.clone)(defaults);
    const leningdeel = Object.assign(Object.assign({}, defaults), { naam: summaryMortagePart.label, toewijzing, leningdeel: Object.assign(Object.assign({}, defaults.leningdeel), { leningdeelNummer: summaryMortagePart.rekeningnummer, oorspronkelijkeHoofdsom: Object.assign(Object.assign({}, defaults.leningdeel.oorspronkelijkeHoofdsom), { waarde: summaryMortagePart.startBedrag }), rente: Object.assign(Object.assign({}, defaults.leningdeel.rente), { periodieken: [
                    Object.assign(Object.assign({}, defaults.leningdeel.rente.periodieken[0]), { percentage: Object.assign(Object.assign({}, defaults.leningdeel.rente.periodieken[0].percentage), { waarde: (_a = summaryMortagePart.rentePercentageBerekend) !== null && _a !== void 0 ? _a : 0 }), ingangsdatum: startDate !== null && startDate !== void 0 ? startDate : defaults.leningdeel.rente.periodieken[0].ingangsdatum, einddatum: endDate !== null && endDate !== void 0 ? endDate : defaults.leningdeel.rente.periodieken[0].einddatum }),
                ] }), saldoPer: {
                datum: date_1.DateUtils.toDateTimeString(taxTermEndDate),
                bedrag: Object.assign(Object.assign({}, (_b = defaults.leningdeel.saldoPer) === null || _b === void 0 ? void 0 : _b.bedrag), { waarde: summaryMortagePart.eindstand }),
            }, renteaftrekTot: endDate !== null && endDate !== void 0 ? endDate : defaults.leningdeel.renteaftrekTot, fiscaalKader: summaryMortagePart.eigenWoningSchuld === 0 ? 'Box3' : 'Box1' }) });
    if (startDate && endDate) {
        leningdeel.duur.ingangsdatum = startDate;
        leningdeel.duur.einddatum = endDate;
    }
    if (isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Lineair, leningdeel) ||
        isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Annuiteit, leningdeel) ||
        isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Krediet, leningdeel) ||
        isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Aflosvrij, leningdeel)) {
        leningdeel.opname = {
            opnames: Object.assign(Object.assign({}, defaults.opname.opnames), { periodieken: [Object.assign(Object.assign({}, defaults.opname.opnames.periodieken[0]), { ingangsdatum: leningdeel.duur.ingangsdatum, einddatum: leningdeel.duur.einddatum })] }),
        };
        leningdeel.aflossing = {
            aflossingen: Object.assign(Object.assign({}, defaults.aflossing.aflossingen), { periodieken: [
                    Object.assign(Object.assign({}, defaults.aflossing.aflossingen.periodieken[0]), { ingangsdatum: leningdeel.duur.ingangsdatum, einddatum: leningdeel.duur.einddatum }),
                ] }),
        };
    }
    if (isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Spaar, leningdeel) ||
        isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Leven, leningdeel)) {
        leningdeel.premie = Object.assign(Object.assign({}, defaults.premie), { einddatum: leningdeel.duur.einddatum });
    }
    if (isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Krediet, leningdeel) &&
        summaryMortagePart.startBedrag) {
        leningdeel.limiet.waarde = summaryMortagePart.startBedrag;
    }
    if (isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Spaar, leningdeel) ||
        isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Leven, leningdeel)) {
        leningdeel.verzekeringsPeriode = Object.assign(Object.assign({}, defaults.verzekeringsPeriode), { ingangsdatum: leningdeel.duur.ingangsdatum, einddatum: leningdeel.duur.einddatum });
    }
    if (isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Bankspaarhypotheek, leningdeel) ||
        isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Belegging, leningdeel)) {
        leningdeel.reguliereOpbouw = Object.assign(Object.assign({}, defaults.reguliereOpbouw), { doelkapitaal: {
                waarde: (_c = summaryMortagePart.startBedrag) !== null && _c !== void 0 ? _c : 0,
            } });
    }
    if (isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Bankspaarhypotheek, leningdeel)) {
        leningdeel.inleg.periodieken[0].ingangsdatum = leningdeel.duur.ingangsdatum;
        leningdeel.inleg.periodieken[0].einddatum = leningdeel.duur.einddatum;
        leningdeel.rekeningPeriode = Object.assign(Object.assign({}, defaults.rekeningPeriode), { ingangsdatum: leningdeel.duur.ingangsdatum, einddatum: leningdeel.duur.einddatum });
    }
    if (isLeningOfType(summaryMortagePart, aqopi_types_1.Aqopi.Summary.MortgagePartType.Belegging, leningdeel)) {
        leningdeel.premie.periodieken[0].ingangsdatum = leningdeel.duur.ingangsdatum;
        leningdeel.premie.periodieken[0].einddatum = leningdeel.duur.einddatum;
        leningdeel.beleggingsPeriode = Object.assign(Object.assign({}, defaults.beleggingsPeriode), { ingangsdatum: leningdeel.duur.ingangsdatum, einddatum: leningdeel.duur.einddatum });
    }
    return leningdeel;
}
function isLeningOfType({ soortHypotheekAfgeleid }, type, leningdeel) {
    return soortHypotheekAfgeleid === type;
}
exports.isLeningOfType = isLeningOfType;
