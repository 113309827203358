"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapFiscaleWoonStaat = void 0;
const mappings_1 = require("../mappings");
function mapFiscaleWoonStaat(summary) {
    const huidigAdresMapped = mapHuidigAdres(summary);
    if (!huidigAdresMapped)
        return;
    if (!huidigAdresMapped.land)
        return;
    // TODO: is er een lookup lijst voor TIN?
    const fiscaleWoonStaat = {
        land: huidigAdresMapped.land,
        tin: '',
    };
    return fiscaleWoonStaat;
}
exports.mapFiscaleWoonStaat = mapFiscaleWoonStaat;
function mapHuidigAdres(val) {
    var _a, _b;
    const huidigAdres = (_b = (_a = val.persoonsgegevens) === null || _a === void 0 ? void 0 : _a.adressen) === null || _b === void 0 ? void 0 : _b.val.find(v => v.huidig);
    if (huidigAdres) {
        const huidigAdresMapped = (0, mappings_1.mapAdres)(huidigAdres);
        return huidigAdresMapped;
    }
    return;
}
