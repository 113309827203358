"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapNationaliteit = void 0;
function mapNationaliteit(landCode, landNaam) {
    const nationaliteit = {
        naam: landNaam,
        code: landCode,
        weergaveNaam: landNaam
    };
    return nationaliteit;
}
exports.mapNationaliteit = mapNationaliteit;
