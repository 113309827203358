"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapFiscaalInwonerVanNederland = exports.mapEerderGehuwd = exports.mapBurgerlijkeStaat = exports.mapAanvrager = void 0;
const types_1 = require("../types");
const date_1 = require("../utils/date");
const map_nationaliteiten_1 = require("./map-nationaliteiten");
const map_fiscale_woon_staat_1 = require("./map-fiscale-woon-staat");
const map_legitimatie_1 = require("./map-legitimatie");
const map_adressen_1 = require("./map-adressen");
function mapAanvrager(defaults, summary) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    const persoonsgegevens = summary.persoonsgegevens;
    const persoon = persoonsgegevens === null || persoonsgegevens === void 0 ? void 0 : persoonsgegevens.aanvrager;
    if (!persoonsgegevens || !persoon)
        return;
    const geboorteland = (_a = persoon.geboorteland) === null || _a === void 0 ? void 0 : _a.val;
    const geboortelandCode = (_c = (_b = persoon.geboortelandCode) === null || _b === void 0 ? void 0 : _b.val) !== null && _c !== void 0 ? _c : '';
    const nationaliteit = geboorteland ? (0, map_nationaliteiten_1.mapNationaliteit)(geboortelandCode, geboorteland) : undefined;
    const adressen = (_d = persoonsgegevens.adressen) === null || _d === void 0 ? void 0 : _d.val;
    const huidigSumAdres = adressen ? adressen.find((v) => v.huidig) : undefined;
    const huidigAdres = huidigSumAdres ? (0, map_adressen_1.mapAdres)(huidigSumAdres) : undefined;
    const emailAdres = (_e = persoon.emailAdres) === null || _e === void 0 ? void 0 : _e.val;
    const fiscaleWoonStaat = (0, map_fiscale_woon_staat_1.mapFiscaleWoonStaat)(summary);
    const nederlandsIngezetene = (huidigAdres === null || huidigAdres === void 0 ? void 0 : huidigAdres.land) ? huidigAdres.land.code === 'NL' : defaults.nederlandsIngezetene;
    const fiscaalInwonerVanNederland = mapFiscaalInwonerVanNederland(summary);
    const idDocs = (_f = persoonsgegevens.identificatiedocumenten) === null || _f === void 0 ? void 0 : _f.val;
    const huidigeLegitimatie = idDocs ? (0, map_legitimatie_1.mapHuidigeLegitimatie)(idDocs) : undefined;
    const geboortedatum = ((_g = persoon.geboortedatum) === null || _g === void 0 ? void 0 : _g.val)
        ? date_1.DateUtils.toDateTimeString(date_1.DateUtils.fromAqopiDate(persoon.geboortedatum.val))
        : undefined;
    const burgerlijkeStaat = persoon === null || persoon === void 0 ? void 0 : persoon.burgerlijkeStaat;
    const aowKortingsjaren = (_k = (_j = (_h = summary.pensioenen) === null || _h === void 0 ? void 0 : _h.algemeen) === null || _j === void 0 ? void 0 : _j.val.aowKortingsjaren) !== null && _k !== void 0 ? _k : 0;
    const client = {
        voornaam: (_m = (_l = persoon.voornamen) === null || _l === void 0 ? void 0 : _l.val) !== null && _m !== void 0 ? _m : defaults.voornaam,
        voorletters: (_p = (_o = persoon.initialen) === null || _o === void 0 ? void 0 : _o.val) !== null && _p !== void 0 ? _p : defaults.voorletters,
        tussenvoegsels: (_r = (_q = persoon.voorvoegselsGeslachtsnaam) === null || _q === void 0 ? void 0 : _q.val) !== null && _r !== void 0 ? _r : defaults.tussenvoegsels,
        achternaam: (_t = (_s = persoon.geslachtsnaam) === null || _s === void 0 ? void 0 : _s.val) !== null && _t !== void 0 ? _t : defaults.achternaam,
        geboortedatum: geboortedatum !== null && geboortedatum !== void 0 ? geboortedatum : defaults.geboortedatum,
        geboorteplaats: (_v = (_u = persoon.geboorteplaats) === null || _u === void 0 ? void 0 : _u.val) !== null && _v !== void 0 ? _v : defaults.geboorteplaats,
        externalId: defaults.externalId,
        nationaliteiten: nationaliteit ? [nationaliteit] : defaults.nationaliteiten,
        nederlandsIngezetene: nederlandsIngezetene !== null && nederlandsIngezetene !== void 0 ? nederlandsIngezetene : defaults.nederlandsIngezetene,
        fiscaalInwonerVanNederland: fiscaalInwonerVanNederland !== null && fiscaalInwonerVanNederland !== void 0 ? fiscaalInwonerVanNederland : defaults.fiscaalInwonerVanNederland,
        // TODO: opmerking: op dit moment alleen Nederlandse adressen
        uitsluitendOfMedeFiscaalInwonerBuitenNederland: false,
        fiscaleWoonStaat: fiscaleWoonStaat ? [fiscaleWoonStaat] : defaults.fiscaleWoonStaat,
        iban: defaults.iban,
        contactgegevens: {
            telefoonnummers: (_w = defaults.contactgegevens) === null || _w === void 0 ? void 0 : _w.telefoonnummers,
            emailadressen: emailAdres
                ? [
                    {
                        emailadres: emailAdres,
                        werkOfPrive: 'Ander',
                    },
                ]
                : (_x = defaults.contactgegevens) === null || _x === void 0 ? void 0 : _x.emailadressen,
        },
        adresgegevens: huidigAdres !== null && huidigAdres !== void 0 ? huidigAdres : defaults.adresgegevens,
        burgerlijkeStaat: burgerlijkeStaat ? mapBurgerlijkeStaat(burgerlijkeStaat) : defaults.burgerlijkeStaat,
        legitimatie: huidigeLegitimatie !== null && huidigeLegitimatie !== void 0 ? huidigeLegitimatie : defaults.legitimatie,
        arbeidsverleden: defaults.arbeidsverleden,
        aowKortingsjaren,
        roker: false,
        geslacht: persoon.geslacht ? mapGeslacht(persoon.geslacht) : types_1.Omniplan.Geslacht.Onbekend,
    };
    const eerderGehuwd = mapEerderGehuwd(summary);
    if (eerderGehuwd) {
        client.eerderGehuwd = eerderGehuwd.eerderGehuwd;
        client.datumOntbinding = eerderGehuwd.datumOntbinding;
        client.notitieEerderGehuwd = eerderGehuwd.notitieEerderGehuwd;
    }
    // TODO: onbekende waarde, geboortenaam
    // client.heeftAfwijkendeGeboortenaam: false,
    // client.geboortenaam: '',
    // client.geboortenaamTussenvoegsel: '',
    return client;
}
exports.mapAanvrager = mapAanvrager;
function mapGeslacht(geslacht) {
    if (geslacht.val === 'Man') {
        return types_1.Omniplan.Geslacht.Man;
    }
    else if (geslacht.val === 'Vrouw') {
        return types_1.Omniplan.Geslacht.Vrouw;
    }
    return types_1.Omniplan.Geslacht.Onbekend;
}
function mapBurgerlijkeStaat(burgerlijkeStaat) {
    var _a, _b, _c;
    if ((_a = burgerlijkeStaat === null || burgerlijkeStaat === void 0 ? void 0 : burgerlijkeStaat.geregistreerdPartnerschap) === null || _a === void 0 ? void 0 : _a.val) {
        return types_1.Omniplan.BurgerlijkeStaat.Partnerregistratieinbeperktegemeenschapvangoederen;
    }
    if ((_b = burgerlijkeStaat === null || burgerlijkeStaat === void 0 ? void 0 : burgerlijkeStaat.gehuwd) === null || _b === void 0 ? void 0 : _b.val) {
        return types_1.Omniplan.BurgerlijkeStaat.Gehuwdgemeenschapvangoederen;
    }
    if ((_c = burgerlijkeStaat === null || burgerlijkeStaat === void 0 ? void 0 : burgerlijkeStaat.indSamenwonendEnFiscaalPartnerschap) === null || _c === void 0 ? void 0 : _c.val) {
        return types_1.Omniplan.BurgerlijkeStaat.Samenwonendmetsamenlevingscontract;
    }
    return types_1.Omniplan.BurgerlijkeStaat.Alleenstaand;
}
exports.mapBurgerlijkeStaat = mapBurgerlijkeStaat;
function mapEerderGehuwd(val) {
    var _a, _b;
    const notitieEerderGehuwd = {
        eerderGehuwd: false,
    };
    const voormaligePartners = (_b = (_a = val.persoonsgegevens) === null || _a === void 0 ? void 0 : _a.voormaligePartners) === null || _b === void 0 ? void 0 : _b.val;
    if (voormaligePartners) {
        const indGehuwd = voormaligePartners.find(v => v.indGehuwd && !!v.indGehuwd);
        if (indGehuwd) {
            if (indGehuwd.indGescheiden && !!indGehuwd.indGescheiden) {
                notitieEerderGehuwd.eerderGehuwd = true;
                if (indGehuwd.datumOntbinding) {
                    notitieEerderGehuwd.datumOntbinding = date_1.DateUtils.fromAqopiDate(indGehuwd.datumOntbinding);
                }
                notitieEerderGehuwd.notitieEerderGehuwd = `${indGehuwd.achternaam}, ${indGehuwd.initialen}`;
            }
        }
    }
    return notitieEerderGehuwd;
}
exports.mapEerderGehuwd = mapEerderGehuwd;
function mapFiscaalInwonerVanNederland(val) {
    // TODO: opmerking: defaults to true
    return true;
}
exports.mapFiscaalInwonerVanNederland = mapFiscaalInwonerVanNederland;
