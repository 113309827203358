"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapPropertyAddress = exports.mapAdres = void 0;
function mapAdres(address) {
    if (!address)
        return;
    return {
        straat: address.straat,
        huisnummer: address.huisnummer,
        toevoeging: address.huisnummerToevoeging,
        postcode: address.postcode,
        woonplaats: address.woonplaatsnaam,
        gemeente: address.gemeenteVanInschrijving,
        //land: lookupLand(DEFAULT_LAND_CODE)
    };
}
exports.mapAdres = mapAdres;
function mapPropertyAddress(property) {
    return {
        straat: property.straatNaam,
        huisnummer: property.huisnummer,
        toevoeging: property.huisnummerToevoeging,
        postcode: property.postcode,
        woonplaats: property.plaatsnaam,
        //land: lookupLand(DEFAULT_LAND_CODE)
    };
}
exports.mapPropertyAddress = mapPropertyAddress;
