"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapEmptyHypothecaireLening = exports.mapCleanDossier = exports.mapDossier = void 0;
const map_aanvrager_1 = require("./map-aanvrager");
const map_children_1 = require("./map-children");
const map_referenties_1 = require("./map-referenties");
const map_onderwerpen_1 = require("./map-onderwerpen");
function mapDossier(defaults, summary, partnerSummary) {
    var _a, _b;
    const clientDefaults = defaults.find(({ toewijzing }) => toewijzing === 'Client').volwassenPersoon;
    const partnerDefaults = (_a = defaults.find(({ toewijzing }) => toewijzing === 'Partner')) === null || _a === void 0 ? void 0 : _a.volwassenPersoon;
    const client = (0, map_aanvrager_1.mapAanvrager)(clientDefaults, summary);
    if (!client) {
        throw Error('Error mapping client');
    }
    if ((partnerSummary && !partnerDefaults) || (!partnerSummary && partnerDefaults)) {
        throw Error('Error mapping partner');
    }
    const partner = partnerSummary && partnerDefaults
        ? (0, map_aanvrager_1.mapAanvrager)(partnerDefaults, partnerSummary)
        : undefined;
    const children = (0, map_children_1.mapChildren)(summary, partnerSummary);
    const referenties = (0, map_referenties_1.mapReferenties)(summary, partnerSummary);
    const onderwerpen = (0, map_onderwerpen_1.mapOnderwerpen)(defaults, summary, partnerSummary);
    const dossier = {
        client: client,
        partner: partner,
        children: children,
        dossier: {
            naam: (_b = getDossierName(client)) !== null && _b !== void 0 ? _b : new Date().toLocaleString(),
            referenties: referenties,
        },
        onderwerpen: onderwerpen,
    };
    return dossier;
}
exports.mapDossier = mapDossier;
function mapCleanDossier({ onderwerpen: onderwerpenDefaults, volwassenPersoon: persoonDefaults }, mapDefaults, summary, partnerSummary) {
    var _a;
    const client = (0, map_aanvrager_1.mapAanvrager)(persoonDefaults, summary);
    if (!client)
        throw Error('Error mapping client');
    const onderwerpen = mapDefaults(onderwerpenDefaults, summary, partnerSummary);
    const dossier = {
        client: client,
        partner: undefined,
        children: [],
        dossier: {
            naam: (_a = getDossierName(client)) !== null && _a !== void 0 ? _a : new Date().toLocaleString(),
            referenties: [],
        },
        onderwerpen: onderwerpen
    };
    return dossier;
}
exports.mapCleanDossier = mapCleanDossier;
function mapEmptyHypothecaireLening(defaults, summary, partnerSummary) {
    const onderwerpen = [];
    const hypothecaireLening = defaults.filter(v => v.$type === 'HypothecaireLening');
    if (hypothecaireLening) {
        for (const c of hypothecaireLening) {
            const containerOnderwerpDefaults = defaults.filter(v => ['AflossingsvrijeHypotheek', 'AnnuiteitenHypotheek', 'BankspaarHypotheek', 'BeleggingsHypotheek', 'KredietHypotheek', 'LevenHypotheek', 'LineaireHypotheek', 'SpaarHypotheek'].find(t => v.$type === t));
            if (containerOnderwerpDefaults) {
                c.onderwerpen.push(...containerOnderwerpDefaults);
            }
            onderwerpen.push(c);
        }
    }
    return onderwerpen;
}
exports.mapEmptyHypothecaireLening = mapEmptyHypothecaireLening;
function getDossierName({ achternaam }) {
    let naam;
    if (achternaam) {
        naam = achternaam;
    }
    return naam;
}
