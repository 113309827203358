"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapLijfrente = exports.mapLijfrenten = void 0;
const omniplan_1 = require("../../types/omniplan");
const clone_1 = require("../../utils/clone");
const date_1 = require("../../utils/date");
function mapLijfrenten(combinedData, defaults, aowDates) {
    var _a, _b, _c, _d, _e, _f, _g;
    const clientLijfrenteIncome = (_c = (_b = (_a = combinedData.aanvrager1.betaaldePremies) === null || _a === void 0 ? void 0 : _a.lijfrente) === null || _b === void 0 ? void 0 : _b.val) !== null && _c !== void 0 ? _c : [];
    const partnerLijfrenteIncome = (_g = (_f = (_e = (_d = combinedData.aanvrager2) === null || _d === void 0 ? void 0 : _d.betaaldePremies) === null || _e === void 0 ? void 0 : _e.lijfrente) === null || _f === void 0 ? void 0 : _f.val) !== null && _g !== void 0 ? _g : [];
    const clientLijfrente = clientLijfrenteIncome
        .filter(({ premie }) => !!premie)
        .map((premie) => mapLijfrente(premie, defaults.client[0], aowDates.client));
    const partnerLijfrente = partnerLijfrenteIncome
        .filter(({ premie }) => !!premie)
        .map((premie) => mapLijfrente(premie, defaults.partner[0], aowDates.partner));
    return [...clientLijfrente, ...partnerLijfrente];
}
exports.mapLijfrenten = mapLijfrenten;
function mapLijfrente(premie, defaults, aowDate) {
    if (!premie.premie) {
        throw new Error('Can not convert empty premie to lijfrente!');
    }
    defaults = (0, clone_1.clone)(defaults);
    const lijfrente = Object.assign(Object.assign({}, defaults), { naam: premie.label, fiscaalRegime: omniplan_1.FiscaalRegime.GeslotenVanaf1Januari2014, premie: Object.assign(Object.assign({}, defaults.premie), { bedrag: Object.assign(Object.assign({}, defaults.premie.bedrag), { waarde: premie.premie }) }) });
    if (aowDate) {
        lijfrente.duur.einddatum = date_1.DateUtils.toDateTimeString(aowDate);
        lijfrente.premie.einddatum = lijfrente.duur.einddatum;
    }
    return lijfrente;
}
exports.mapLijfrente = mapLijfrente;
