"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AqopiSummaryToOmniplan = void 0;
const mappings_1 = require("./mappings");
const omniplan_api_1 = require("./utils/omniplan-api");
var AqopiSummaryToOmniplan;
(function (AqopiSummaryToOmniplan) {
    function execute(opts, summary, partnerSummary) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const api = new omniplan_api_1.OmniplanApi.Controller((_a = opts.clientId) !== null && _a !== void 0 ? _a : "", (_b = opts.clientSecret) !== null && _b !== void 0 ? _b : "", opts.access_token, opts.expires_in);
            yield api.authorize();
            const onderwerpen = (0, mappings_1.getOnderwerpenForDefaultsRequest)(summary, partnerSummary);
            const personen = (0, mappings_1.getPersonenForDefaultsRequest)(summary, partnerSummary);
            const defaults = yield (0, mappings_1.getDefaults)(api, onderwerpen, personen);
            /**
             * // store mapped defaults to disk
             * const mappedDefaultsPath = path.join(__dirname, '../docs', 'mapped-defaults.json');
             * console.debug(mappedDefaultsPath);
             * const json = JSON.stringify(defaults, null, 2);
             * fs.writeFileSync(mappedDefaultsPath, json, 'utf-8');
             */
            const dossier = yield transform(defaults, summary, partnerSummary);
            if (dossier) {
                const result = yield api.create(dossier);
                return Object.assign(Object.assign({}, result), { dossier });
            }
        });
    }
    AqopiSummaryToOmniplan.execute = execute;
    function transform(defaults, summary, partnerSummary) {
        return __awaiter(this, void 0, void 0, function* () {
            const dossier = (0, mappings_1.mapDossier)(defaults, summary, partnerSummary);
            return dossier;
        });
    }
    AqopiSummaryToOmniplan.transform = transform;
})(AqopiSummaryToOmniplan = exports.AqopiSummaryToOmniplan || (exports.AqopiSummaryToOmniplan = {}));
