"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOnderwerpen = void 0;
const aqopi_data_combine_1 = require("@iwize-aqopi/aqopi-data-combine");
const onderwerpen_1 = require("./onderwerpen");
const map_pensioenen_1 = require("./onderwerpen/map-pensioenen");
const map_hypothecaire_inschrijvingen_1 = require("./onderwerpen/map-hypothecaire-inschrijvingen");
const separate_combined_data_1 = require("../utils/separate-combined-data");
const map_utils_1 = require("./map-utils");
const map_persoonlijke_leningen_1 = require("./onderwerpen/map-persoonlijke-leningen");
const map_studieschulden_1 = require("./onderwerpen/map-studieschulden");
const map_doorlopendkrediet_1 = require("./onderwerpen/map-doorlopendkrediet");
const map_direct_ingaande_lijfrente_1 = require("./onderwerpen/map-direct-ingaande-lijfrente");
const map_lijfrente_1 = require("./onderwerpen/map-lijfrente");
const date_1 = require("../utils/date");
const prepare_defaults_1 = require("./prepare-defaults");
const uuid_1 = require("uuid");
function mapOnderwerpen(defaultedTypes, summary, partnerSummary) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const clientDefaults = defaultedTypes.find(({ toewijzing }) => toewijzing === 'Client').onderwerpen;
    const partnerDefaults = (_a = defaultedTypes.find(({ toewijzing }) => toewijzing === 'Partner')) === null || _a === void 0 ? void 0 : _a.onderwerpen;
    const coupleDefaults = (_b = defaultedTypes.find(({ toewijzing }) => toewijzing === 'Samen')) === null || _b === void 0 ? void 0 : _b.onderwerpen;
    const defaults = [
        ...clientDefaults,
        ...(partnerDefaults || []),
        ...(coupleDefaults || []),
    ];
    const onderwerpen = [];
    const combinedResult = aqopi_data_combine_1.AqopiCombine.combine(JSON.parse(JSON.stringify(summary)), partnerSummary && JSON.parse(JSON.stringify(partnerSummary)));
    const combinedData = (0, separate_combined_data_1.separateCombinedData)(combinedResult);
    const { aanvrager1, aanvrager2, combined: couple } = combinedData;
    const hasPartner = !!aanvrager2;
    let inschrijvingen = [];
    let inschrijvingenMortgageParts = [];
    let inschrijvingLeningen = [];
    const othersInschrijving = {
        id: (0, uuid_1.v4)(),
        rang: 1,
        bedrag: { waarde: 0 },
        maatschappij: {
            code: 'ZZ',
            naam: 'Anders',
            weergaveNaam: 'Anders',
        },
    };
    // TODO: Run this as part of the default-service as well, so we get a HypotheekLening onderwerp
    // per inschrijving + toewijzing combination
    let hypothecaireInschrijvingenDefaults = (0, map_hypothecaire_inschrijvingen_1.getHypothecaireInschrijvingDefaults)(defaults);
    if (combinedData.schulden.hypotheekdelen.count) {
        const inschrijvingBundles = (0, map_hypothecaire_inschrijvingen_1.getHypothecaireInschrijvingBundles)(combinedData.schulden.hypotheekdelen.all, hypothecaireInschrijvingenDefaults);
        inschrijvingBundles.forEach(({ inschrijving, mortgageParts }) => {
            inschrijvingen.push(inschrijving);
            inschrijvingenMortgageParts.push(mortgageParts);
        });
        inschrijvingLeningen = (0, map_hypothecaire_inschrijvingen_1.getInschrijvingLeningen)(inschrijvingen, inschrijvingenMortgageParts, aanvrager1.uuid);
    }
    const aowDates = (0, map_utils_1.getAowDates)(summary, partnerSummary);
    const clientBirthdate = date_1.DateUtils.fromAqopiDate((_f = (_e = (_d = (_c = summary.persoonsgegevens) === null || _c === void 0 ? void 0 : _c.aanvrager) === null || _d === void 0 ? void 0 : _d.geboortedatum) === null || _e === void 0 ? void 0 : _e.val) !== null && _f !== void 0 ? _f : (_h = (_g = partnerSummary === null || partnerSummary === void 0 ? void 0 : partnerSummary.persoonsgegevens) === null || _g === void 0 ? void 0 : _g.partner) === null || _h === void 0 ? void 0 : _h.val.geboortedatum);
    const partnerBirthdate = date_1.DateUtils.fromAqopiDate((_m = (_l = (_k = (_j = partnerSummary === null || partnerSummary === void 0 ? void 0 : partnerSummary.persoonsgegevens) === null || _j === void 0 ? void 0 : _j.aanvrager) === null || _k === void 0 ? void 0 : _k.geboortedatum) === null || _l === void 0 ? void 0 : _l.val) !== null && _m !== void 0 ? _m : (_p = (_o = summary.persoonsgegevens) === null || _o === void 0 ? void 0 : _o.partner) === null || _p === void 0 ? void 0 : _p.val.geboortedatum);
    // MAP Dienstbetrekking
    onderwerpen.push(...mapOnDefaults(clientDefaults, 'Dienstbetrekking', aanvrager1, (summary, defaults) => (0, onderwerpen_1.mapDienstbetrekkingen)(defaults, aowDates, summary)));
    if (aanvrager2 && partnerDefaults) {
        onderwerpen.push(...mapOnDefaults(partnerDefaults, 'Dienstbetrekking', aanvrager2, (summary, defaults) => (0, onderwerpen_1.mapDienstbetrekkingen)(defaults, aowDates, summary)));
    }
    // MAP HypothecaireLening
    const hypothecaireLeningDefaults = getAllDefaultsOfType(defaultedTypes, 'HypothecaireLening');
    if (hypothecaireLeningDefaults.length) {
        if (!((_q = combinedData.schulden) === null || _q === void 0 ? void 0 : _q.hypotheekdelen.all)) {
            throw new Error('No mortgageParts, yet we have defaults for HypothecaireLening');
        }
        const taxTermEndDateCollection = (0, map_utils_1.getTaxTermEndDateCollection)(summary, partnerSummary);
        const leningdeelDefaults = defaults.filter((v) => [
            'AflossingsvrijeHypotheek',
            'AnnuiteitenHypotheek',
            'BankspaarHypotheek',
            'BeleggingsHypotheek',
            'KredietHypotheek',
            'LevenHypotheek',
            'LineaireHypotheek',
            'SpaarHypotheek',
        ].find((t) => v.$type === t));
        const hypothecaireLeningen = (0, onderwerpen_1.mapHypothecaireLeningen)(inschrijvingLeningen, othersInschrijving, taxTermEndDateCollection, combinedData.aanvrager1.uuid, hypothecaireLeningDefaults, leningdeelDefaults);
        onderwerpen.push(...correctToewijzing(hypothecaireLeningen, hasPartner));
    }
    // MAP SpecifiekTegoed
    const specifiekTegoedDefaults = getAllDefaultsOfType(defaultedTypes, 'SpecifiekTegoed');
    if (specifiekTegoedDefaults.length) {
        onderwerpen.push(...correctToewijzing((0, onderwerpen_1.mapSpecifiekTegoeden)(specifiekTegoedDefaults, combinedData), hasPartner));
    }
    // MAP Woning
    const woningDefaults = getAllDefaultsOfType(defaultedTypes, 'Woning');
    if (woningDefaults.length) {
        onderwerpen.push(...correctToewijzing((0, onderwerpen_1.mapWoningen)(combinedData, inschrijvingLeningen, othersInschrijving, woningDefaults), hasPartner));
    }
    // MAP PremieDepot
    const premieDepotDefaults = getAllDefaultsOfType(defaultedTypes, 'PremieDepot');
    if (premieDepotDefaults.length) {
        onderwerpen.push(...correctToewijzing((0, onderwerpen_1.mapPremieDepots)(combinedData, premieDepotDefaults), hasPartner));
    }
    // MAP Lening [StudieSchuld]
    const hasStudieSchulden = !!((_s = (_r = summary.schulden) === null || _r === void 0 ? void 0 : _r.studieschuld) === null || _s === void 0 ? void 0 : _s.val.studieschuld) ||
        !!((_u = (_t = partnerSummary === null || partnerSummary === void 0 ? void 0 : partnerSummary.schulden) === null || _t === void 0 ? void 0 : _t.studieschuld) === null || _u === void 0 ? void 0 : _u.val.studieschuld);
    const leningDefaults = getAllDefaultsOfType(defaultedTypes, 'Lening');
    if (hasStudieSchulden && leningDefaults.length) {
        onderwerpen.push(...correctToewijzing((0, map_studieschulden_1.mapStudieschulden)(leningDefaults, summary, partnerSummary), hasPartner));
    }
    // MAP PersoonlijkeLening
    const persoonlijkeLeningDefaults = getAllDefaultsOfType(defaultedTypes, 'PersoonlijkeLening');
    if (persoonlijkeLeningDefaults.length) {
        onderwerpen.push(...correctToewijzing((0, map_persoonlijke_leningen_1.mapPersoonlijkeLeningen)(combinedData, persoonlijkeLeningDefaults), hasPartner));
    }
    // MAP DoorlopendKrediet
    const doorlopendKredietDefaults = getAllDefaultsOfType(defaultedTypes, 'DoorlopendKrediet');
    if (doorlopendKredietDefaults.length) {
        onderwerpen.push(...correctToewijzing((0, map_doorlopendkrediet_1.mapDoorlopendeKredieten)(combinedData, doorlopendKredietDefaults), hasPartner));
    }
    // MAP PensioenUPO & Pensioenaanspraak
    const pensioenUPODefaults = getAllDefaultsOfType(defaultedTypes, 'PensioenUPO');
    const pensioenAanspraakDefaults = getAllDefaultsOfType(defaultedTypes, 'Pensioenaanspraak');
    if (pensioenUPODefaults.length || pensioenAanspraakDefaults.length) {
        const pensioenen = (0, map_pensioenen_1.mapAllPensioenen)(combinedData, pensioenUPODefaults, pensioenAanspraakDefaults, clientBirthdate, partnerBirthdate);
        onderwerpen.push(...correctToewijzing(pensioenen, hasPartner));
    }
    // MAP DirectIngaandeLijfrente
    const directIngaandeLijfrenteDefaults = getAllDefaultsOfType(defaultedTypes, 'DirectIngaandeLijfrente');
    if (directIngaandeLijfrenteDefaults.length) {
        onderwerpen.push(...correctToewijzing((0, map_direct_ingaande_lijfrente_1.mapDirectIngaandeLijfrenten)(combinedData, directIngaandeLijfrenteDefaults), hasPartner));
    }
    // MAP Lijfrenteverzekering
    const lijfrenteDefaults = getAllDefaultsOfType(defaultedTypes, 'Lijfrenteverzekering');
    if (lijfrenteDefaults.length) {
        onderwerpen.push(...correctToewijzing((0, map_lijfrente_1.mapLijfrenten)(combinedData, lijfrenteDefaults, aowDates), hasPartner));
    }
    return onderwerpen;
}
exports.mapOnderwerpen = mapOnderwerpen;
function mapOnDefaults(defaults, type, summary, mapFn) {
    var _a;
    if (!defaults.some((defs) => defs.$type === type)) {
        return [];
    }
    const onderwerpDefaults = defaults.filter((v) => v.$type === type);
    return (_a = mapFn(summary, onderwerpDefaults)) !== null && _a !== void 0 ? _a : [];
}
function getAllDefaultsOfType(defaultedTypes, type) {
    var _a, _b, _c, _d;
    const clientDefaults = defaultedTypes.find(({ toewijzing }) => toewijzing === 'Client').onderwerpen;
    const partnerDefaults = (_a = defaultedTypes.find(({ toewijzing }) => toewijzing === 'Partner')) === null || _a === void 0 ? void 0 : _a.onderwerpen;
    const coupleDefaults = (_b = defaultedTypes.find(({ toewijzing }) => toewijzing === 'Samen')) === null || _b === void 0 ? void 0 : _b.onderwerpen;
    const result = {
        client: clientDefaults.filter((def) => def.$type === type),
        partner: ((_c = partnerDefaults === null || partnerDefaults === void 0 ? void 0 : partnerDefaults.filter((def) => def.$type === type)) !== null && _c !== void 0 ? _c : []),
        couple: ((_d = coupleDefaults === null || coupleDefaults === void 0 ? void 0 : coupleDefaults.filter((def) => def.$type === type)) !== null && _d !== void 0 ? _d : []),
        length: 0,
    };
    result.length =
        result.client.length + result.partner.length + result.couple.length;
    return result;
}
function correctToewijzing(onderwerpen, hasPartner = false) {
    if (hasPartner) {
        onderwerpen.forEach((onderwerp) => {
            console.log(onderwerp.$type);
            const { toewijzing } = prepare_defaults_1.ONDERWERP_DEFAULTS.find((def) => def.type === onderwerp.$type);
            if (toewijzing === 'Samen') {
                onderwerp.toewijzing = toewijzing;
            }
        });
    }
    return onderwerpen;
}
