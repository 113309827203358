"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapReferentie = exports.mapReferenties = void 0;
function mapReferenties(summary, parnerSummary) {
    const referenties = [];
    return referenties;
}
exports.mapReferenties = mapReferenties;
function mapReferentie() {
    throw Error('not implemented');
}
exports.mapReferentie = mapReferentie;
