"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDirectIngaandeLijfrente = exports.mapDirectIngaandeLijfrente = exports.mapDirectIngaandeLijfrenten = void 0;
const omniplan_1 = require("../../types/omniplan");
const clone_1 = require("../../utils/clone");
function mapDirectIngaandeLijfrenten(combinedData, defaults) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const clientLijfrenteIncome = (_d = (_c = (_b = (_a = combinedData.aanvrager1.inkomens) === null || _a === void 0 ? void 0 : _a.belastingaangifte) === null || _b === void 0 ? void 0 : _b.inkomens) === null || _c === void 0 ? void 0 : _c.val.filter(isDirectIngaandeLijfrente)) !== null && _d !== void 0 ? _d : [];
    const partnerLijfrenteIncome = (_j = (_h = (_g = (_f = (_e = combinedData.aanvrager2) === null || _e === void 0 ? void 0 : _e.inkomens) === null || _f === void 0 ? void 0 : _f.belastingaangifte) === null || _g === void 0 ? void 0 : _g.inkomens) === null || _h === void 0 ? void 0 : _h.val.filter(isDirectIngaandeLijfrente)) !== null && _j !== void 0 ? _j : [];
    const clientLijfrente = clientLijfrenteIncome.map((income) => mapDirectIngaandeLijfrente(income, defaults.client[0]));
    const partnerLijfrente = partnerLijfrenteIncome.map((income) => mapDirectIngaandeLijfrente(income, defaults.partner[0]));
    return [...clientLijfrente, ...partnerLijfrente];
}
exports.mapDirectIngaandeLijfrenten = mapDirectIngaandeLijfrenten;
function mapDirectIngaandeLijfrente(income, defaults) {
    const lijfrente = Object.assign(Object.assign({}, (0, clone_1.clone)(defaults)), { naam: income.instantieWerkgever, waardebepaling: 'Opgeven', jaarlijkseUitkering: {
            waarde: income.brutoloon,
        } });
    if (+income.sivCode === 59) {
        lijfrente.lijfrenteType = omniplan_1.LijfrenteType.Bancair;
        lijfrente.fiscaalRegime = omniplan_1.FiscaalRegime.Stamrecht;
    }
    else {
        lijfrente.lijfrenteType = omniplan_1.LijfrenteType.Verzekerd;
        lijfrente.fiscaalRegime = omniplan_1.FiscaalRegime.GeslotenVanaf1Januari2014;
    }
    return lijfrente;
}
exports.mapDirectIngaandeLijfrente = mapDirectIngaandeLijfrente;
function isDirectIngaandeLijfrente(income) {
    return +income.sivCode === 59 || +income.sivCode === 60;
}
exports.isDirectIngaandeLijfrente = isDirectIngaandeLijfrente;
