"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapPremieDepot = exports.mapPremieDepots = void 0;
const mapping_1 = require("../../utils/mapping");
function mapPremieDepots(combinedData, defaults) {
    const summaryPremieDepots = combinedData.bezittingen.premiedepots;
    const premieDepots = [];
    if (!summaryPremieDepots || !summaryPremieDepots.all.length) {
        return [];
    }
    if (mapping_1.MappingUtils.checkDefaults(defaults.client, summaryPremieDepots.aanvrager1, 'bankrekeningen')) {
        premieDepots.push(...summaryPremieDepots.aanvrager1.map((depot, idx) => mapPremieDepot(depot, defaults.client[idx])));
    }
    if (mapping_1.MappingUtils.checkDefaults(defaults.partner, summaryPremieDepots.aanvrager2, 'bankrekeningen')) {
        premieDepots.push(...summaryPremieDepots.aanvrager2.map((depot, idx) => mapPremieDepot(depot, defaults.partner[idx])));
    }
    if (mapping_1.MappingUtils.checkDefaults(defaults.couple, summaryPremieDepots.combined, 'bankrekeningen')) {
        premieDepots.push(...summaryPremieDepots.combined.map((depot, idx) => mapPremieDepot(depot, defaults.couple[idx])));
    }
    return premieDepots;
}
exports.mapPremieDepots = mapPremieDepots;
function mapPremieDepot(summaryPart, defaults) {
    return Object.assign(Object.assign({}, defaults), { naam: `${summaryPart.rekeningnummer} (${summaryPart.label})` });
}
exports.mapPremieDepot = mapPremieDepot;
