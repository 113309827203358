"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasOldPensions = exports.hasLivePensions = exports.mapPensioenenUPO = exports.mapAllPensioenen = void 0;
const date_1 = require("../../utils/date");
const map_pensioen_utils_1 = require("./map-pensioen-utils");
function mapAllPensioenen(combinedData, upoDefaults, aanspraakDefaults, clientBirthdate, partnerBirthdate) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    let clientPensions = {
        pensioenUPOs: [],
        pensioenAanspraken: [],
    };
    let partnerPensions = {
        pensioenUPOs: [],
        pensioenAanspraken: [],
    };
    const clientAOWDateString = (_d = (_c = (_b = (_a = upoDefaults.client) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.ouderdomspensioen) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.ingangsdatum;
    const partnerAOWDateString = (_h = (_g = (_f = (_e = upoDefaults.partner) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.ouderdomspensioen) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.ingangsdatum;
    if ((_j = combinedData.aanvrager1.pensioenen) === null || _j === void 0 ? void 0 : _j.pensioenDetails) {
        clientPensions = mapPensioenen(combinedData.aanvrager1.pensioenen, upoDefaults.client, aanspraakDefaults.client, partnerAOWDateString, partnerBirthdate);
    }
    if ((_l = (_k = combinedData.aanvrager2) === null || _k === void 0 ? void 0 : _k.pensioenen) === null || _l === void 0 ? void 0 : _l.pensioenDetails) {
        partnerPensions = mapPensioenen((_m = combinedData.aanvrager2) === null || _m === void 0 ? void 0 : _m.pensioenen, upoDefaults.partner, aanspraakDefaults.partner, clientAOWDateString, clientBirthdate);
    }
    return [
        ...clientPensions.pensioenUPOs,
        ...clientPensions.pensioenAanspraken,
        ...partnerPensions.pensioenUPOs,
        ...partnerPensions.pensioenAanspraken,
    ];
}
exports.mapAllPensioenen = mapAllPensioenen;
function mapPensioenen(summaryPensioenen, upoDefaults, aanspraakDefaults, aowDatePartner, birthdatePartner) {
    var _a;
    if (!(summaryPensioenen === null || summaryPensioenen === void 0 ? void 0 : summaryPensioenen.pensioenDetails)) {
        throw new Error('No PensioenDetails present in the Summary');
    }
    const { upoCollections, aanspraakCollections /*, aow, aowDate*/ } = (0, map_pensioen_utils_1.getProcessedPensionDetails)(summaryPensioenen);
    let pensioenUPOs = [];
    let pensioenAanspraken = [];
    if (upoCollections.length) {
        if (!upoDefaults) {
            throw new Error('No Defaults were found for PensioenUPO');
        }
        let defaults = upoDefaults[0];
        // const summaryAowDateString = DateUtils.toDateTimeString(aowDate);
        // if (summaryAowDateString) {
        // 	defaults.duur.ingangsdatum = summaryAowDateString;
        // }
        pensioenUPOs = mapPensioenenUPO(upoCollections, defaults, aowDatePartner, birthdatePartner);
    }
    if (aanspraakCollections.length) {
        if (!aanspraakDefaults) {
            throw new Error('No Defaults were found for Pensioenaanspraak');
        }
        if (!((_a = summaryPensioenen.algemeen) === null || _a === void 0 ? void 0 : _a.val)) {
            throw new Error('No General info was found for Pensions');
        }
        let defaults = aanspraakDefaults[0];
        pensioenAanspraken = mapPensioenAanspraken(aanspraakCollections, 
        //summaryPensioenen.algemeen.val,
        defaults);
    }
    return {
        pensioenUPOs,
        pensioenAanspraken,
    };
}
function mapPensioenenUPO(collections, defaults, aowDateStringPartner, birthdatePartner) {
    // Defaults
    const ouderdomsPensioenDefaults = defaults.ouderdomspensioen[0];
    const partnerPensioenVoorPensioenDefaults = defaults.partnerPensioenBijOverlijdenVoorPensioenDatumAanspraken[0];
    const partnerPensioenNaPensioenDefaults = defaults.partnerPensioenBijOverlijdenNaPensioenDatumAanspraken[0];
    const wezenPensioenVoorPensioenDefaults = defaults.wezenPensioenBijOverlijdenVoorPensioenDatum;
    const wezenPensioenNaPensioenDefaults = defaults.wezenPensioenBijOverlijdenNaPensioenDatum;
    // Result
    const pensioenUPOs = [];
    // Set einddatum for defaults
    const einddatum = aowDateStringPartner !== null && aowDateStringPartner !== void 0 ? aowDateStringPartner : ouderdomsPensioenDefaults.ingangsdatum;
    partnerPensioenVoorPensioenDefaults.einddatum = einddatum;
    partnerPensioenNaPensioenDefaults.einddatum = einddatum;
    collections.forEach((collection) => {
        var _a, _b;
        const ouderdomspensioen = collection.pensioenen.map((pension) => mapPensioenUPOAanspraak(pension, ouderdomsPensioenDefaults));
        const partnerPensionsCombined = collection.partnerpensioenen.map((pension) => (0, map_pensioen_utils_1.mapPensioenUPOAanspraakBijOverlijden)(pension, partnerPensioenVoorPensioenDefaults, partnerPensioenNaPensioenDefaults, birthdatePartner));
        const { startDate, endDate, totalAmount } = (0, map_pensioen_utils_1.getPensioenSummary)([
            ...collection.pensioenen,
            ...collection.partnerpensioenen,
            ...collection.wezenpensioenen,
        ]);
        const partnerAansprakenVoorPensioenen = partnerPensionsCombined
            .map(({ partnerAanspraakVoorPensioen }) => partnerAanspraakVoorPensioen)
            .filter((partnerAanspraakVoorPensioen) => !!partnerAanspraakVoorPensioen);
        const partnerAansprakenNaPensioenen = partnerPensionsCombined
            .map(({ partnerAanspraakNaPensioen }) => partnerAanspraakNaPensioen)
            .filter((partnerAanspraakNaPensioen) => !!partnerAanspraakNaPensioen);
        const { wezenPensioenVoorPensioen, wezenPensioenNaPensioen } = (0, map_pensioen_utils_1.getTotalWezenPensioen)(collection.wezenpensioenen, wezenPensioenVoorPensioenDefaults, wezenPensioenNaPensioenDefaults);
        pensioenUPOs.push(Object.assign(Object.assign({}, defaults), { duur: {
                ingangsdatum: (_a = date_1.DateUtils.toDateTimeString(startDate)) !== null && _a !== void 0 ? _a : defaults.duur.ingangsdatum,
                einddatum: (_b = date_1.DateUtils.toDateTimeString(endDate)) !== null && _b !== void 0 ? _b : defaults.duur.einddatum,
            }, ouderdomspensioen: ouderdomspensioen.length ? ouderdomspensioen : undefined, partnerPensioenBijOverlijdenVoorPensioenDatumAanspraken: partnerAansprakenVoorPensioenen.length
                ? partnerAansprakenVoorPensioenen
                : undefined, partnerPensioenBijOverlijdenNaPensioenDatumAanspraken: partnerAansprakenNaPensioenen.length
                ? partnerAansprakenNaPensioenen
                : undefined, wezenPensioenBijOverlijdenVoorPensioenDatum: wezenPensioenVoorPensioen, wezenPensioenBijOverlijdenNaPensioenDatum: wezenPensioenNaPensioen, opgebouwdPensioen: totalAmount
                ? Object.assign(Object.assign({}, defaults.opgebouwdPensioen), { waarde: totalAmount }) : defaults.opgebouwdPensioen }));
    });
    return pensioenUPOs;
}
exports.mapPensioenenUPO = mapPensioenenUPO;
function mapPensioenAanspraken(collections, 
//algemeen: GeneralPensionInfo,
defaults) {
    const wezenPensioenDefaults = defaults.wezenPensioen;
    const pensioenAanspraken = collections.map((collection) => {
        const { startDate, endDate } = (0, map_pensioen_utils_1.getPensioenSummary)([
            ...collection.pensioenen,
            ...collection.partnerpensioenen,
            ...collection.wezenpensioenen,
        ]);
        const pensioenDatumStartStr = date_1.DateUtils.toDateTimeString(startDate);
        let pensioenDatumEindStr;
        if (endDate) {
            pensioenDatumEindStr = date_1.DateUtils.toDateTimeString(endDate);
        }
        else if (startDate) {
            const pensioenDatumEind = new Date(startDate);
            pensioenDatumEind.setFullYear(startDate.getFullYear() + 38);
            pensioenDatumEindStr = date_1.DateUtils.toDateTimeString(pensioenDatumEind);
        }
        let retirementPensionTotal = 0;
        collection.pensioenen.forEach((pensioen) => {
            var _a;
            retirementPensionTotal += (_a = (pensioen.opgebouwd || pensioen.teBereiken)) !== null && _a !== void 0 ? _a : 0;
        });
        let partnerPensionBeforeRetirementTotal = 0;
        let partnerPensionAfterRetirementTotal = 0;
        collection.partnerpensioenen.forEach((pensioen) => {
            var _a, _b, _c, _d;
            partnerPensionBeforeRetirementTotal += (_b = (_a = pensioen.bedragen) === null || _a === void 0 ? void 0 : _a.verzekerdBedrag) !== null && _b !== void 0 ? _b : 0;
            partnerPensionAfterRetirementTotal += (_d = (_c = pensioen.bedragen) === null || _c === void 0 ? void 0 : _c.verzekerdBedragNaPens) !== null && _d !== void 0 ? _d : 0;
        });
        const { wezenPensioenVoorPensioen } = (0, map_pensioen_utils_1.getTotalWezenPensioen)(collection.wezenpensioenen, wezenPensioenDefaults, wezenPensioenDefaults);
        return Object.assign(Object.assign({}, defaults), { duur: {
                ingangsdatum: pensioenDatumStartStr !== null && pensioenDatumStartStr !== void 0 ? pensioenDatumStartStr : defaults.duur.ingangsdatum,
                einddatum: pensioenDatumEindStr !== null && pensioenDatumEindStr !== void 0 ? pensioenDatumEindStr : defaults.duur.einddatum,
            }, pensioenDatum: pensioenDatumStartStr !== null && pensioenDatumStartStr !== void 0 ? pensioenDatumStartStr : defaults.pensioenDatum, ouderdomspensioen: Object.assign(Object.assign({}, defaults.ouderdomspensioen), { waarde: retirementPensionTotal }), partnerPensioenBijOverlijdenVoorPensioenDatumIsBedragOfPercentage: 'Bedrag', partnerPensioenBijOverlijdenVoorPensioenDatumBedrag: Object.assign(Object.assign({}, defaults.partnerPensioenBijOverlijdenVoorPensioenDatumBedrag), { waarde: partnerPensionBeforeRetirementTotal }), partnerPensioenBijOverlijdenVoorPensioenDatumPercentage: undefined, partnerPensioenBijOverlijdenNaPensioenDatumIsBedragOfPercentage: 'Bedrag', partnerPensioenBijOverlijdenNaPensioenDatumBedrag: Object.assign(Object.assign({}, defaults.partnerPensioenBijOverlijdenVoorPensioenDatumBedrag), { waarde: partnerPensionAfterRetirementTotal }), partnerPensioenBijOverlijdenNaPensioenDatumPercentage: undefined, wezenPensioen: wezenPensioenVoorPensioen !== null && wezenPensioenVoorPensioen !== void 0 ? wezenPensioenVoorPensioen : defaults.wezenPensioen });
    });
    return pensioenAanspraken;
}
function mapPensioenUPOAanspraak(details, defaults) {
    var _a, _b;
    return Object.assign(Object.assign({}, defaults), { ingangsdatum: (details.vanafDatum && date_1.DateUtils.toDateTimeString(date_1.DateUtils.fromAqopiDateRequired(details.vanafDatum))) ||
            defaults.ingangsdatum, einddatum: date_1.DateUtils.toDateTimeString(date_1.DateUtils.fromAqopiDate(details.totDatum)), levenslang: (_a = details.totOverlijden) !== null && _a !== void 0 ? _a : (details.totDatum ? false : defaults.levenslang), bedrag: Object.assign(Object.assign({}, defaults.bedrag), { waarde: (_b = details.teBereiken) !== null && _b !== void 0 ? _b : defaults.bedrag.waarde }) });
}
function hasLivePensions(summary) {
    const { upoCollections } = (0, map_pensioen_utils_1.getProcessedPensionDetails)(summary.pensioenen);
    return !!upoCollections.length;
}
exports.hasLivePensions = hasLivePensions;
function hasOldPensions(summary) {
    const { aanspraakCollections } = (0, map_pensioen_utils_1.getProcessedPensionDetails)(summary.pensioenen);
    return !!aanspraakCollections.length;
}
exports.hasOldPensions = hasOldPensions;
