"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProcessedPensionDetails = exports.getTotalWezenPensioen = exports.mapPensioenUPOAanspraakBijOverlijden = exports.getPensioenSummary = void 0;
const date_1 = require("../../utils/date");
;
function getPensioenSummary(pensions) {
    let startDate;
    let endDate;
    let totalAmount = 0;
    pensions.forEach((pension) => {
        if (pension.vanafDatum) {
            let date = date_1.DateUtils.fromAqopiDateRequired(pension.vanafDatum);
            if (!startDate || +date < +startDate) {
                startDate = date;
            }
        }
        if (pension.totDatum) {
            let date = date_1.DateUtils.fromAqopiDateRequired(pension.totDatum);
            if (!endDate || +date > +endDate) {
                endDate = date;
            }
        }
        if (pension.opgebouwd && pension.opgebouwd > 0) {
            totalAmount += pension.opgebouwd;
        }
    });
    return {
        startDate,
        endDate,
        totalAmount,
    };
}
exports.getPensioenSummary = getPensioenSummary;
function mapPensioenUPOAanspraakBijOverlijden(details, defaultsVoorPensioen, defaultsNaPensioen, birthdatePartner) {
    var _a, _b, _c, _d;
    let partnerAanspraakVoorPensioen;
    let partnerAanspraakNaPensioen;
    if ((_a = details.bedragen) === null || _a === void 0 ? void 0 : _a.verzekerdBedrag) {
        partnerAanspraakVoorPensioen = getPensioenUPOAanspraakBijOverlijden(details, (_b = details.bedragen) === null || _b === void 0 ? void 0 : _b.verzekerdBedrag, defaultsVoorPensioen, birthdatePartner);
    }
    if ((_c = details.bedragen) === null || _c === void 0 ? void 0 : _c.verzekerdBedragNaPens) {
        partnerAanspraakNaPensioen = getPensioenUPOAanspraakBijOverlijden(details, (_d = details.bedragen) === null || _d === void 0 ? void 0 : _d.verzekerdBedragNaPens, defaultsNaPensioen, birthdatePartner);
    }
    return { partnerAanspraakVoorPensioen, partnerAanspraakNaPensioen };
}
exports.mapPensioenUPOAanspraakBijOverlijden = mapPensioenUPOAanspraakBijOverlijden;
function getTotalWezenPensioen(orphanPensions, wezenPensioenVoorPensioenDefaults, wezenPensioenNaPensioenDefaults) {
    const wezenPensionsCombined = orphanPensions.map((pension) => mapWezenPensioen(pension, wezenPensioenVoorPensioenDefaults, wezenPensioenNaPensioenDefaults));
    const wezenPensioenVoorPensioenen = wezenPensionsCombined
        .map(({ wezenAanspraakVoorPensioen }) => wezenAanspraakVoorPensioen)
        .filter((wezenAanspraakVoorPensioen) => !!wezenAanspraakVoorPensioen);
    const wezenPensioenNaPensioenen = wezenPensionsCombined
        .map(({ wezenAanspraakNaPensioen }) => wezenAanspraakNaPensioen)
        .filter((wezenAanspraakNaPensioen) => !!wezenAanspraakNaPensioen);
    const wezenPensioenVoorPensioen = getCumulativeWezenPensioen(wezenPensioenVoorPensioenen, wezenPensioenVoorPensioenDefaults);
    const wezenPensioenNaPensioen = getCumulativeWezenPensioen(wezenPensioenNaPensioenen, wezenPensioenNaPensioenDefaults);
    return {
        wezenPensioenVoorPensioen,
        wezenPensioenNaPensioen
    };
}
exports.getTotalWezenPensioen = getTotalWezenPensioen;
function getProcessedPensionDetails(pensionSummary) {
    var _a, _b;
    const details = ((_a = pensionSummary === null || pensionSummary === void 0 ? void 0 : pensionSummary.pensioenDetails) === null || _a === void 0 ? void 0 : _a.detailsPerHerkenningsnummer).val;
    const pensionDetails = details.pensioenen;
    const upoCollections = [];
    const aanspraakCollections = [];
    pensionDetails.forEach(({ herkenningsNummer, value: pensionCollection }) => {
        const collection = Object.assign(Object.assign({}, pensionCollection), { herkenningsNummer });
        if (pensionCollection.pensioenen.some(isLivePension)) {
            upoCollections.push(collection);
        }
        else if (pensionCollection.pensioenen.some(isOldPension)) {
            aanspraakCollections.push(collection);
        }
        else if (pensionCollection.partnerpensioenen.some(isLivePartnerPension)) {
            upoCollections.push(collection);
        }
        else if (pensionCollection.partnerpensioenen.some(isOldPartnerPension)) {
            aanspraakCollections.push(collection);
        }
        else if (pensionCollection.pensioenUitkeringen.length) {
            aanspraakCollections.push(collection);
        }
        else {
            //??
            upoCollections.push(collection);
        }
    });
    return {
        upoCollections,
        aanspraakCollections,
        aow: details.aow,
        aowDate: date_1.DateUtils.fromAqopiDate((_b = pensionSummary === null || pensionSummary === void 0 ? void 0 : pensionSummary.algemeen) === null || _b === void 0 ? void 0 : _b.val.aowDatum),
    };
}
exports.getProcessedPensionDetails = getProcessedPensionDetails;
function getPensioenUPOAanspraakBijOverlijden(details, bedrag, defaults, birthdatePartner) {
    let startDate = undefined;
    let endDate = undefined;
    let ingangsdatum = defaults.ingangsdatum;
    let einddatum = defaults.einddatum;
    if (!details.totDatum && details.totLeeftijdJaren && birthdatePartner) {
        endDate = new Date(birthdatePartner);
        endDate.setFullYear(birthdatePartner.getFullYear() + details.totLeeftijdJaren);
        if (details.totLeeftijdMaanden) {
            endDate.setMonth(birthdatePartner.getMonth() + details.totLeeftijdMaanden);
        }
    }
    if (!details.vanafDatum && details.vanafLeeftijdJaren && birthdatePartner) {
        startDate = new Date(birthdatePartner);
        startDate.setFullYear(birthdatePartner.getFullYear() + details.vanafLeeftijdJaren);
        if (details.vanafLeeftijdMaanden) {
            startDate.setMonth(birthdatePartner.getMonth() + details.vanafLeeftijdMaanden);
        }
    }
    if (endDate || details.totDatum) {
        einddatum = date_1.DateUtils.toDateTimeString(endDate !== null && endDate !== void 0 ? endDate : date_1.DateUtils.fromAqopiDate(details.totDatum));
    }
    else if (details.totOverlijden) {
        einddatum = undefined;
    }
    if (startDate || details.vanafDatum) {
        ingangsdatum = date_1.DateUtils.toDateTimeString(startDate !== null && startDate !== void 0 ? startDate : date_1.DateUtils.fromAqopiDate(details.vanafDatum));
    }
    else if (details.vanafOverlijdenPartner) {
        ingangsdatum = undefined;
    }
    else if (details.totOverlijden) {
        ingangsdatum = defaults.einddatum;
    }
    if (!ingangsdatum && !einddatum) {
        console.warn('Partner Pension without clear start & end date found.');
    }
    return Object.assign(Object.assign({}, defaults), { ingangsdatum,
        einddatum, vanafOverlijden: !!details.vanafOverlijdenPartner, levenslang: !einddatum, bedragOfPercentage: 'Bedrag', bedragOfPercentagePercentage: undefined, bedragOfPercentageBedrag: Object.assign(Object.assign({}, defaults.bedragOfPercentageBedrag), { waarde: bedrag }) });
}
function getWezenPensioen(details, bedrag, defaults) {
    var _a;
    return Object.assign(Object.assign({}, defaults), { wezenpensioenIsBedragOfPercentage: 'Bedrag', wezenpensioenBedrag: Object.assign(Object.assign({}, defaults.wezenpensioenBedrag), { waarde: bedrag }), wezenpensioenPercentage: undefined, wezenPensioenTotLeeftijdVanKind: (_a = details.totLeeftijdJaren) !== null && _a !== void 0 ? _a : defaults.wezenPensioenTotLeeftijdVanKind });
}
function mapWezenPensioen(details, defaultsVoorPensioen, defaultsNaPensioen) {
    var _a, _b, _c, _d;
    let wezenAanspraakVoorPensioen;
    let wezenAanspraakNaPensioen;
    if ((_a = details.bedragen) === null || _a === void 0 ? void 0 : _a.verzekerdBedrag) {
        wezenAanspraakVoorPensioen = getWezenPensioen(details, (_b = details.bedragen) === null || _b === void 0 ? void 0 : _b.verzekerdBedrag, defaultsVoorPensioen);
    }
    if ((_c = details.bedragen) === null || _c === void 0 ? void 0 : _c.verzekerdBedragNaPens) {
        wezenAanspraakNaPensioen = getWezenPensioen(details, (_d = details.bedragen) === null || _d === void 0 ? void 0 : _d.verzekerdBedragNaPens, defaultsNaPensioen);
    }
    return { wezenAanspraakVoorPensioen, wezenAanspraakNaPensioen };
}
function getCumulativeWezenPensioen(wezenPensioenen, defaults) {
    return wezenPensioenen.length && defaults
        ? wezenPensioenen.reduce((wezenPensioen, { wezenpensioenBedrag, wezenPensioenTotLeeftijdVanKind }) => {
            var _a;
            return (Object.assign(Object.assign({}, wezenPensioen), { wezenpensioenBedrag: Object.assign(Object.assign({}, wezenPensioen.wezenpensioenBedrag), { waarde: (((_a = wezenPensioen.wezenpensioenBedrag) === null || _a === void 0 ? void 0 : _a.waarde) || 0) + ((wezenpensioenBedrag === null || wezenpensioenBedrag === void 0 ? void 0 : wezenpensioenBedrag.waarde) || 0) }), wezenPensioenTotLeeftijdVanKind: Math.max(wezenPensioen.wezenPensioenTotLeeftijdVanKind, wezenPensioenTotLeeftijdVanKind) }));
        }, Object.assign(Object.assign({}, defaults), { wezenpensioenIsBedragOfPercentage: 'Bedrag', wezenpensioenPercentage: undefined }))
        : undefined;
}
function isOldPension({ teBereiken, opgebouwd }) {
    return !!teBereiken && !!opgebouwd && teBereiken === opgebouwd;
}
function isLivePension({ teBereiken, opgebouwd }) {
    return !!teBereiken && !!opgebouwd && teBereiken > opgebouwd;
}
function isOldPartnerPension({ bedragen }) {
    var _a, _b, _c, _d;
    const verzekerdBedrag = ((_a = bedragen === null || bedragen === void 0 ? void 0 : bedragen.verzekerdBedrag) !== null && _a !== void 0 ? _a : 0) + ((_b = bedragen === null || bedragen === void 0 ? void 0 : bedragen.verzekerdBedragNaPens) !== null && _b !== void 0 ? _b : 0);
    const opgebouwdBedrag = ((_c = bedragen === null || bedragen === void 0 ? void 0 : bedragen.opgebouwdBedrag) !== null && _c !== void 0 ? _c : 0) + ((_d = bedragen === null || bedragen === void 0 ? void 0 : bedragen.opgebouwdBedragNaPens) !== null && _d !== void 0 ? _d : 0);
    return !!bedragen && !!verzekerdBedrag && !!opgebouwdBedrag && verzekerdBedrag === opgebouwdBedrag;
}
function isLivePartnerPension({ bedragen }) {
    var _a, _b, _c, _d;
    const verzekerdBedrag = ((_a = bedragen === null || bedragen === void 0 ? void 0 : bedragen.verzekerdBedrag) !== null && _a !== void 0 ? _a : 0) + ((_b = bedragen === null || bedragen === void 0 ? void 0 : bedragen.verzekerdBedragNaPens) !== null && _b !== void 0 ? _b : 0);
    const opgebouwdBedrag = ((_c = bedragen === null || bedragen === void 0 ? void 0 : bedragen.opgebouwdBedrag) !== null && _c !== void 0 ? _c : 0) + ((_d = bedragen === null || bedragen === void 0 ? void 0 : bedragen.opgebouwdBedragNaPens) !== null && _d !== void 0 ? _d : 0);
    return !!bedragen && !!verzekerdBedrag && !!opgebouwdBedrag && verzekerdBedrag > opgebouwdBedrag;
}
