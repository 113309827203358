"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.separateCombinedData = void 0;
const getValues_1 = require("./getValues");
function separateCombinedData(combinedData) {
    const { aanvrager1, aanvrager2, combined } = combinedData;
    const aanvrager1Exclusive = removeMutualRecords(aanvrager1);
    const aanvrager2Exclusive = aanvrager2 ? removeMutualRecords(aanvrager2) : undefined;
    return {
        aanvrager1: aanvrager1Exclusive,
        aanvrager2: aanvrager2Exclusive,
        combined: combined,
        persoonsgegevens: {
            kinderen: getPropertySummary(aanvrager1Exclusive, aanvrager2Exclusive, combined, 'persoonsgegevens.kinderen.val', 'gezamenlijkeKinderen'),
            adressen: getPropertySummary(aanvrager1Exclusive, aanvrager2Exclusive, combined, 'persoonsgegevens.adressen.val', 'gezamenlijkeAdressen'),
        },
        bezittingen: {
            onroerendGoed: getPropertySummary(aanvrager1Exclusive, aanvrager2Exclusive, combined, 'bezittingen.onroerendGoed.val', 'gezamenlijkOnroerendGoed'),
            bankrekeningen: getPropertySummary(aanvrager1Exclusive, aanvrager2Exclusive, combined, 'bezittingen.bankrekeningen.val', 'gezamenlijkeBankrekeningen'),
            kapitaalverzekeringen: getPropertySummary(aanvrager1Exclusive, aanvrager2Exclusive, combined, 'bezittingen.kapitaalverzekeringen.val', 'gezamenlijkeKapitaalverzekeringen'),
            premiedepots: getPropertySummary(aanvrager1Exclusive, aanvrager2Exclusive, combined, 'bezittingen.premiedepots.val', 'gezamenlijkePremiedepots'),
        },
        schulden: {
            overigeSchulden: getPropertySummary(aanvrager1Exclusive, aanvrager2Exclusive, combined, 'schulden.overigeSchulden.val', 'gezamenlijkeOverigeSchulden'),
            hypotheekdelen: getPropertySummary(aanvrager1Exclusive, aanvrager2Exclusive, combined, 'schulden.hypotheekdelen.val', 'gezamenlijkeHypotheekdelen'),
        },
    };
}
exports.separateCombinedData = separateCombinedData;
function removeMutualRecords(summary) {
    return Object.assign(Object.assign({}, summary), { persoonsgegevens: Object.assign(Object.assign({}, summary.persoonsgegevens), { kinderen: removeSharedProperties(summary.persoonsgegevens.kinderen), adressen: removeSharedProperties(summary.persoonsgegevens.adressen) }), bezittingen: Object.assign(Object.assign({}, summary.bezittingen), { onroerendGoed: removeSharedProperties(summary.bezittingen.onroerendGoed), bankrekeningen: removeSharedProperties(summary.bezittingen.bankrekeningen), kapitaalverzekeringen: removeSharedProperties(summary.bezittingen.kapitaalverzekeringen), premiedepots: removeSharedProperties(summary.bezittingen.premiedepots) }), schulden: Object.assign(Object.assign({}, summary.schulden), { overigeSchulden: removeSharedProperties(summary.schulden.overigeSchulden), hypotheekdelen: removeSharedProperties(summary.schulden.hypotheekdelen) }) });
}
function removeSharedProperties(data) {
    if (!data) {
        return;
    }
    return Object.assign(Object.assign({}, data), { val: data.val.filter((prop) => !prop.relatedTo || prop.relatedTo.length === 1) });
}
function getPropertySummary(aanvrager1, aanvrager2, combined, soloPath, combinedPath) {
    var _a, _b, _c;
    const aanvrager1Data = (_a = (0, getValues_1.getPathValue)(aanvrager1, soloPath)) !== null && _a !== void 0 ? _a : [];
    const aanvrager2Data = (_b = (0, getValues_1.getPathValue)(aanvrager2, soloPath)) !== null && _b !== void 0 ? _b : [];
    const combinedData = (_c = (0, getValues_1.getPathValue)(combined, combinedPath)) !== null && _c !== void 0 ? _c : [];
    return {
        aanvrager1: aanvrager1Data,
        aanvrager2: aanvrager2Data,
        combined: combinedData,
        all: aanvrager1Data.concat(aanvrager2Data, combinedData),
        count: aanvrager1Data.length + aanvrager2Data.length + combinedData.length,
    };
}
