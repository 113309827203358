"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegitimatieSoort = void 0;
var LegitimatieSoort;
(function (LegitimatieSoort) {
    LegitimatieSoort["Europeseidentiteitskaart"] = "Europeseidentiteitskaart";
    LegitimatieSoort["Paspoort"] = "Paspoort";
    LegitimatieSoort["Rijbewijs"] = "Rijbewijs";
    LegitimatieSoort["Visum"] = "Visum";
})(LegitimatieSoort = exports.LegitimatieSoort || (exports.LegitimatieSoort = {}));
