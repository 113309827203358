"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDoorlopendKrediet = exports.mapDoorlopendKrediet = exports.mapDoorlopendeKredieten = void 0;
const date_1 = require("../../utils/date");
const clone_1 = require("../../utils/clone");
const iban_to_maatschappij_1 = require("../../utils/iban-to-maatschappij");
const map_utils_1 = require("../map-utils");
function mapDoorlopendeKredieten(combinedData, defaults) {
    const debts = combinedData.schulden.overigeSchulden;
    const clientDate = getDebtSampleDate(combinedData.aanvrager1);
    const partnerDate = combinedData.aanvrager2 && getDebtSampleDate(combinedData.aanvrager2);
    const combinedDate = partnerDate ? (partnerDate > clientDate ? partnerDate : clientDate) : clientDate;
    const debtsClient = debts.aanvrager1
        .filter(isDoorlopendKrediet)
        .map((debt) => mapDoorlopendKrediet(debt, defaults.client[0], clientDate));
    let debtsPartner = [];
    if (partnerDate) {
        debtsPartner = debts.aanvrager2
            .filter(isDoorlopendKrediet)
            .map((debt) => mapDoorlopendKrediet(debt, defaults.partner[0], partnerDate));
    }
    const debtsCombined = debts.combined
        .filter(isDoorlopendKrediet)
        .map((debt) => mapDoorlopendKrediet(debt, defaults.couple[0], combinedDate));
    return [...debtsClient, ...debtsPartner, ...debtsCombined];
}
exports.mapDoorlopendeKredieten = mapDoorlopendeKredieten;
function mapDoorlopendKrediet(debt, defaults, sampleDate) {
    var _a, _b, _c, _d, _e;
    defaults = (0, clone_1.clone)(defaults);
    const startDate = new Date(sampleDate);
    startDate.setFullYear(sampleDate.getFullYear() - 1);
    startDate.setDate(startDate.getDate() + 1);
    const krediet = Object.assign(Object.assign({}, defaults), { naam: `${debt.label} ${debt.rekeningnummer}`, duur: {
            ingangsdatum: date_1.DateUtils.toDateTimeString(startDate),
            einddatum: date_1.DateUtils.toDateTimeString(sampleDate),
        }, maatschappij: (_a = (0, iban_to_maatschappij_1.getMaatschapijForIBAN)(debt.rekeningnummer)) !== null && _a !== void 0 ? _a : defaults.maatschappij, kredietLimiet: {
            waarde: Math.max((_b = debt.startBedrag) !== null && _b !== void 0 ? _b : 0, (_c = debt.saldo.beginstand) !== null && _c !== void 0 ? _c : 0, (_d = debt.saldo.eindstand) !== null && _d !== void 0 ? _d : 0),
        }, saldoOpDatum: {
            datum: date_1.DateUtils.toDateTimeString(sampleDate !== null && sampleDate !== void 0 ? sampleDate : new Date()),
            bedrag: {
                waarde: (_e = debt.saldo.eindstand) !== null && _e !== void 0 ? _e : 0,
            },
        } });
    return krediet;
}
exports.mapDoorlopendKrediet = mapDoorlopendKrediet;
function isDoorlopendKrediet(debt) {
    return !!debt.recordType && +debt.recordType === 5 && !!debt.saldo.eindstand;
}
exports.isDoorlopendKrediet = isDoorlopendKrediet;
function getDebtSampleDate(summary) {
    var _a, _b;
    if (!((_a = summary.schulden) === null || _a === void 0 ? void 0 : _a.overigeSchulden)) {
        throw 'overigeSchulden does not exist on summary';
    }
    return (0, map_utils_1.getTaxTermEndDate)((_b = summary.schulden) === null || _b === void 0 ? void 0 : _b.overigeSchulden);
}
