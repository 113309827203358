"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clone = void 0;
const global = typeof globalThis == 'object' ? globalThis : window;
function clone(obj) {
    if ('structuredClone' in global) {
        return structuredClone(obj);
    }
    else {
        // Note that this does not support Dates, functions, undefined, Maps, Sets, etc
        return JSON.parse(JSON.stringify(obj));
    }
}
exports.clone = clone;
