"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapHuidigeLegitimatie = void 0;
const aqopi_types_1 = require("@iwize-aqopi/aqopi-types");
const types_1 = require("../types");
const date_1 = require("../utils/date");
function mapHuidigeLegitimatie(idDocs) {
    const legitimaties = idDocs.map((idDoc) => mapLegitimatie(idDoc));
    if (legitimaties.length) {
        // sort by newest and take first
        const legitimatie = legitimaties
            .filter(([_, expirationDate]) => expirationDate && expirationDate >= new Date())
            .sort(([_a, expDateA], [_b, expDateB]) => { var _c, _d; return ((_c = expDateB === null || expDateB === void 0 ? void 0 : expDateB.getTime()) !== null && _c !== void 0 ? _c : 0) - ((_d = expDateA === null || expDateA === void 0 ? void 0 : expDateA.getTime()) !== null && _d !== void 0 ? _d : 0); })
            .pop();
        if (legitimatie) {
            return legitimatie[0];
        }
    }
}
exports.mapHuidigeLegitimatie = mapHuidigeLegitimatie;
function mapLegitimatie(val) {
    const expirationDate = date_1.DateUtils.fromAqopiDate(val.geldigTot);
    const legitimatie = {
        soort: mapIdDocType(val.soortDocument),
        nummer: val.nummer,
        afgiftedatum: date_1.DateUtils.toDateTimeString(date_1.DateUtils.fromAqopiDate(val.afgifteDatum)),
        geldigTot: date_1.DateUtils.toDateTimeString(expirationDate),
    };
    // TODO: verblijftvergunning is nu niet ondersteund
    return [legitimatie, expirationDate];
}
function mapIdDocType(val) {
    switch (val) {
        case aqopi_types_1.Aqopi.Summary.IdDocType.Paspoort:
            return types_1.Omniplan.LegitimatieSoort.Paspoort;
            break;
        case aqopi_types_1.Aqopi.Summary.IdDocType.IdKaart:
            return types_1.Omniplan.LegitimatieSoort.Europeseidentiteitskaart;
        default:
            throw Error('onbekend idDocType');
    }
}
