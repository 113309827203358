"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValueArrayLengths = exports.getValueArrays = exports.getPathValue = void 0;
function getPathValue(obj, path) {
    let objectValue = obj;
    const pathParts = path.split('.');
    let nextKey;
    while (objectValue && (nextKey = pathParts.shift())) {
        objectValue = objectValue[nextKey];
    }
    return objectValue;
}
exports.getPathValue = getPathValue;
function getValueArrays(combinedData, soloPath, couplePath) {
    var _a, _b, _c;
    const { aanvrager1, aanvrager2, combined } = combinedData;
    let soloValues1 = (_a = getPathValue(aanvrager1, soloPath)) !== null && _a !== void 0 ? _a : [];
    let soloValues2 = (_b = getPathValue(aanvrager2, soloPath)) !== null && _b !== void 0 ? _b : [];
    let coupleValues = (_c = getPathValue(combined, couplePath)) !== null && _c !== void 0 ? _c : [];
    return {
        aanvrager1: soloValues1,
        aanvrager2: soloValues2,
        aanvragers: soloValues1.concat(soloValues2),
        combined: coupleValues,
    };
}
exports.getValueArrays = getValueArrays;
function getValueArrayLengths(combinedData, soloPath, couplePath) {
    const data = getValueArrays(combinedData, soloPath, couplePath);
    return {
        aanvrager1: data.aanvrager1.length,
        aanvrager2: data.aanvrager2.length,
        aanvragers: data.aanvragers.length,
        combined: data.combined.length,
        total: data.aanvragers.length + data.combined.length,
    };
}
exports.getValueArrayLengths = getValueArrayLengths;
