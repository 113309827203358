"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ouder = exports.OpleidingsNiveau = exports.LeningType = exports.Geslacht = exports.FiscaalKader = exports.BurgerlijkeStaat = void 0;
var BurgerlijkeStaat;
(function (BurgerlijkeStaat) {
    BurgerlijkeStaat["Alleenstaand"] = "Alleenstaand";
    BurgerlijkeStaat["Gehuwdinbeperktegemeenschapvangoederen"] = "Gehuwdinbeperktegemeenschapvangoederen";
    BurgerlijkeStaat["Gehuwdgemeenschapvangoederen"] = "Gehuwdgemeenschapvangoederen";
    BurgerlijkeStaat["Gehuwdmethuwelijksevoorwaarden"] = "Gehuwdmethuwelijksevoorwaarden";
    BurgerlijkeStaat["Gehuwdvolgensbuitenlandsrecht"] = "Gehuwdvolgensbuitenlandsrecht";
    BurgerlijkeStaat["Samenwonendmetsamenlevingscontract"] = "Samenwonendmetsamenlevingscontract";
    BurgerlijkeStaat["Samenwonendzondersamenlevingscontract"] = "Samenwonendzondersamenlevingscontract";
    BurgerlijkeStaat["Partnerregistratieingemeenschapvangoederen"] = "Partnerregistratieingemeenschapvangoederen";
    BurgerlijkeStaat["Partnerregistratieonderregistratievoorwaarden"] = "Partnerregistratieonderregistratievoorwaarden";
    BurgerlijkeStaat["Partnerregistratieinbeperktegemeenschapvangoederen"] = "Partnerregistratieinbeperktegemeenschapvangoederen";
})(BurgerlijkeStaat = exports.BurgerlijkeStaat || (exports.BurgerlijkeStaat = {}));
var FiscaalKader;
(function (FiscaalKader) {
    FiscaalKader["Box1"] = "Box1";
    FiscaalKader["Box3"] = "Box3";
})(FiscaalKader = exports.FiscaalKader || (exports.FiscaalKader = {}));
var Geslacht;
(function (Geslacht) {
    Geslacht["Man"] = "Man";
    Geslacht["Vrouw"] = "Vrouw";
    Geslacht["Onbekend"] = "Onbekend";
})(Geslacht = exports.Geslacht || (exports.Geslacht = {}));
var LeningType;
(function (LeningType) {
    LeningType["Overig"] = "Overig";
    LeningType["SchenkingOpPapier"] = "SchenkingOpPapier";
    LeningType["LeningDGA"] = "LeningDGA";
    LeningType["RestschuldHypotheekBKR"] = "RestschuldHypotheekBKR";
    LeningType["StudieVoorschot"] = "StudieVoorschot";
    LeningType["StudieLening"] = "StudieLening";
    LeningType["OperationalLease"] = "OperationalLease";
})(LeningType = exports.LeningType || (exports.LeningType = {}));
var OpleidingsNiveau;
(function (OpleidingsNiveau) {
    OpleidingsNiveau["Onbekend"] = "Onbekend";
    OpleidingsNiveau["Lageronderwijs"] = "Lageronderwijs";
    OpleidingsNiveau["VoortgezetonderwijsMAVOHAVO"] = "VoortgezetonderwijsMAVOHAVO";
    OpleidingsNiveau["VWO"] = "VWO";
    OpleidingsNiveau["Lagerberoepsonderwijs"] = "Lagerberoepsonderwijs";
    OpleidingsNiveau["Middelbaarberoepsonderwijs"] = "Middelbaarberoepsonderwijs";
    OpleidingsNiveau["Hogerberoepsonderwijs"] = "Hogerberoepsonderwijs";
    OpleidingsNiveau["PostAcademisch"] = "PostAcademisch";
})(OpleidingsNiveau = exports.OpleidingsNiveau || (exports.OpleidingsNiveau = {}));
var Ouder;
(function (Ouder) {
    Ouder["Client"] = "Client";
    Ouder["Partner"] = "Partner";
    Ouder["Samen"] = "Samen";
})(Ouder = exports.Ouder || (exports.Ouder = {}));
