"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapStudieschuld = exports.mapStudieschulden = void 0;
const types_1 = require("../../types");
const date_1 = require("../../utils/date");
const omniplan_1 = require("../../types/omniplan");
const clone_1 = require("../../utils/clone");
const milliSecondsInDay = 1000 * 60 * 60 * 24;
function mapStudieschulden(defaults, summary, partnerSummary) {
    var _a, _b, _c, _d, _e, _f;
    const leningen = [];
    let studieschuld = (_b = (_a = summary.schulden) === null || _a === void 0 ? void 0 : _a.studieschuld) === null || _b === void 0 ? void 0 : _b.val;
    (_d = (_c = summary.schulden) === null || _c === void 0 ? void 0 : _c.studieschuld) === null || _d === void 0 ? void 0 : _d.meta.datum;
    if (studieschuld && !studieschuld.studieschuld.schuldInOpbouwfase) {
        const sampleDate = getDebtSampleDate(summary);
        leningen.push(mapStudieschuld(studieschuld, sampleDate, defaults.client[0], 'Client'));
    }
    studieschuld = (_f = (_e = partnerSummary === null || partnerSummary === void 0 ? void 0 : partnerSummary.schulden) === null || _e === void 0 ? void 0 : _e.studieschuld) === null || _f === void 0 ? void 0 : _f.val;
    if (partnerSummary && studieschuld && !studieschuld.studieschuld.schuldInOpbouwfase) {
        const sampleDate = getDebtSampleDate(partnerSummary);
        leningen.push(mapStudieschuld(studieschuld, sampleDate, defaults.partner[0], 'Partner'));
    }
    return leningen;
}
exports.mapStudieschulden = mapStudieschulden;
function mapStudieschuld(debt, sampleDate, defaults, toewijzing) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    defaults = (0, clone_1.clone)(defaults);
    const studentDebt = debt.studieschuld;
    let hoofdsom = (_a = studentDebt.saldoActueelTotaal) !== null && _a !== void 0 ? _a : studentDebt.saldoStartAflosfaseTotaal;
    const startDate = date_1.DateUtils.fromAqopiDate(studentDebt.begindatumAflosfase);
    const repaymentMonths = getRepaymentMonths(sampleDate, startDate);
    const repaymentThisYear = repaymentMonths * studentDebt.maandtermijnHuidig;
    hoofdsom += repaymentThisYear;
    const lening = Object.assign(Object.assign({}, defaults), { naam: 'Studieschuld', toewijzing, leningType: types_1.Omniplan.LeningType.StudieLening, hoofdsom: Object.assign(Object.assign({}, defaults.hoofdsom), { waarde: Math.floor(hoofdsom) }), rentepercentage: Object.assign(Object.assign({}, defaults.rentepercentage), { waarde: studentDebt.rentepercentageHuidig }), fiscaalKader: omniplan_1.FiscaalKader.Box3, maandlasten: Object.assign(Object.assign({}, defaults.maandlasten), { waarde: Math.floor(studentDebt.maandtermijnHuidig) }), volledigAflossenOpEinddatum: true });
    const ingangsdatum = date_1.DateUtils.toDateTimeString(startDate);
    const einddatum = date_1.DateUtils.toDateTimeString(date_1.DateUtils.fromAqopiDate(studentDebt.einddatumAfgeleid));
    // If the defaults provided opnames, change the enddate
    if (((_b = lening.opnames) === null || _b === void 0 ? void 0 : _b.periodieken.length) === 1) {
        lening.opnames.periodieken[0].einddatum = einddatum;
    }
    if (ingangsdatum && einddatum) {
        lening.duur.ingangsdatum = ingangsdatum;
        lening.duur.einddatum = einddatum;
    }
    let repaymentStartDate = new Date(`${sampleDate.getFullYear() - 1}-01-01`);
    let repaymentEndDate = new Date(`${sampleDate.getFullYear() - 1}-12-31`);
    // If startDate lies within the repayment year
    if (startDate && startDate >= repaymentStartDate) {
        var diffInDays = Math.round((+repaymentEndDate - +startDate) / milliSecondsInDay);
        const repaymentMonthNum = diffInDays > 365 ? 12 : 12 - startDate.getMonth();
        lening.aflossingen = Object.assign(Object.assign({}, defaults.aflossingen), { isWaardevast: (_d = (_c = defaults.aflossingen) === null || _c === void 0 ? void 0 : _c.isWaardevast) !== null && _d !== void 0 ? _d : true, periodieken: [
                Object.assign(Object.assign({}, (_e = defaults.aflossingen) === null || _e === void 0 ? void 0 : _e.periodieken[0]), { ingangsdatum: date_1.DateUtils.toDateTimeString(startDate), einddatum: date_1.DateUtils.toDateTimeString(repaymentEndDate), bedrag: {
                        waarde: Math.floor(studentDebt.maandtermijnHuidig * repaymentMonthNum),
                    } }),
            ] });
        repaymentStartDate = new Date(`${sampleDate.getFullYear()}-01-01`);
        repaymentEndDate = new Date(`${sampleDate.getFullYear()}-12-31`);
        lening.aflossingen.periodieken.push(Object.assign(Object.assign({}, (_f = defaults.aflossingen) === null || _f === void 0 ? void 0 : _f.periodieken[0]), { ingangsdatum: date_1.DateUtils.toDateTimeString(repaymentStartDate), einddatum: date_1.DateUtils.toDateTimeString(repaymentEndDate), bedrag: {
                waarde: Math.floor(studentDebt.maandtermijnHuidig * 12),
            } }));
    }
    // If startDate lies in a previous year
    else if (startDate && startDate < repaymentStartDate) {
        lening.aflossingen = Object.assign(Object.assign({}, defaults.aflossingen), { isWaardevast: (_h = (_g = defaults.aflossingen) === null || _g === void 0 ? void 0 : _g.isWaardevast) !== null && _h !== void 0 ? _h : true, periodieken: [
                Object.assign(Object.assign({}, (_j = defaults.aflossingen) === null || _j === void 0 ? void 0 : _j.periodieken[0]), { ingangsdatum: date_1.DateUtils.toDateTimeString(repaymentStartDate), einddatum: einddatum, bedrag: {
                        waarde: Math.floor(studentDebt.maandtermijnHuidig * 12),
                    } }),
            ] });
    }
    return lening;
}
exports.mapStudieschuld = mapStudieschuld;
function getDebtSampleDate(summary) {
    var _a, _b, _c;
    return (_c = date_1.DateUtils.fromAqopiDate((_b = (_a = summary.schulden) === null || _a === void 0 ? void 0 : _a.studieschuld) === null || _b === void 0 ? void 0 : _b.meta.datum)) !== null && _c !== void 0 ? _c : new Date();
}
function getRepaymentMonths(sampleDate, repaymentStart) {
    const months = sampleDate.getMonth();
    const substractMonths = sampleDate.getFullYear() === repaymentStart.getFullYear()
        ? repaymentStart.getMonth()
        : 0;
    if (sampleDate.getDate() > 24) {
        return months + 1 - substractMonths;
    }
    return months - substractMonths;
}
