"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapWoning = exports.mapWoningen = void 0;
const date_1 = require("../../utils/date");
const map_adressen_1 = require("../map-adressen");
const clone_1 = require("../../utils/clone");
function mapWoningen(combinedData, inschrijvingLeningen, othersInschrijving, defaults) {
    const woningen = [];
    if (combinedData.bezittingen.onroerendGoed.aanvrager1.length) {
        const onroerendGoed = combinedData.bezittingen.onroerendGoed.aanvrager1;
        const inschrijvingen = inschrijvingLeningen
            .filter(({ toewijzing }) => toewijzing === 'Client')
            .map(({ inschrijving }) => inschrijving);
        woningen.push(...mapOnroerendeGoederen(onroerendGoed, defaults.client, inschrijvingen));
    }
    if (combinedData.bezittingen.onroerendGoed.aanvrager2.length) {
        const onroerendGoed = combinedData.bezittingen.onroerendGoed.aanvrager2;
        const inschrijvingen = inschrijvingLeningen
            .filter(({ toewijzing }) => toewijzing === 'Partner')
            .map(({ inschrijving }) => inschrijving);
        woningen.push(...mapOnroerendeGoederen(onroerendGoed, defaults.partner, inschrijvingen));
    }
    if (combinedData.bezittingen.onroerendGoed.combined.length) {
        const onroerendGoed = combinedData.bezittingen.onroerendGoed.combined;
        const inschrijvingen = inschrijvingLeningen
            .filter(({ toewijzing }) => toewijzing === 'Samen')
            .map(({ inschrijving }) => inschrijving);
        woningen.push(...mapOnroerendeGoederen(onroerendGoed, defaults.couple, inschrijvingen));
    }
    if (woningen.length > 0) {
        woningen[0].hypothecaireInschrijvingen.push(Object.assign(Object.assign({}, othersInschrijving), { rang: woningen[0].hypothecaireInschrijvingen.length + 1 }));
    }
    return woningen;
}
exports.mapWoningen = mapWoningen;
function mapOnroerendeGoederen(onroerendGoed, defaults, hypothecaireInschrijvingen) {
    const woningen = [];
    // If there are multiple potential woningen, weed out any that lack important info
    if (onroerendGoed.length > 1) {
        onroerendGoed = onroerendGoed.filter((or) => {
            if (!or.straatNaam || !or.huisnummer) {
                return false;
            }
            if (!or.wozWaarde) {
                // Gather objects with the same properties AND a woz-waarde
                const similarObjects = onroerendGoed.filter((or2) => or2.koopsom === or.koopsom &&
                    or2.bouwjaar === or.bouwjaar &&
                    or2.straatNaam === or.straatNaam &&
                    or2.huisnummer === or.huisnummer &&
                    !!or2.wozWaarde);
                if (similarObjects.length > 0) {
                    return false;
                }
            }
            return true;
        });
    }
    if (onroerendGoed.length === 1) {
        woningen.push(mapWoning(defaults[0], onroerendGoed[0], hypothecaireInschrijvingen));
    }
    else if (onroerendGoed.length > 1) {
        woningen.push(...onroerendGoed.map((woning, idx) => mapWoning(defaults[idx], woning, idx === 0 ? hypothecaireInschrijvingen : [])));
    }
    return woningen;
}
function mapWoning(defaults, property, hypothecaireInschrijvingen) {
    var _a, _b, _c;
    defaults = (0, clone_1.clone)(defaults);
    const waarde = (_a = property.wozWaarde) !== null && _a !== void 0 ? _a : property.koopsom;
    return Object.assign(Object.assign({}, defaults), { adresgegevens: (0, map_adressen_1.mapPropertyAddress)(property), wozWaarde: Object.assign(Object.assign({}, defaults.wozWaarde), { waarde }), marktwaarde: Object.assign(Object.assign({}, defaults.marktwaarde), { waarde }), vrijeVerkoopwaarde: Object.assign(Object.assign({}, defaults.vrijeVerkoopwaarde), { periodieken: [
                Object.assign(Object.assign({}, defaults.vrijeVerkoopwaarde.periodieken[0]), { bedrag: {
                        waarde,
                    } }),
            ] }), peildatum: (_b = date_1.DateUtils.toDateTimeString(date_1.DateUtils.fromAqopiDate(property.peildatum))) !== null && _b !== void 0 ? _b : defaults.peildatum, hypothecaireInschrijvingen, deelClient: Object.assign(Object.assign({}, defaults.deelClient), { waarde: (_c = property.aandeelPercentage) !== null && _c !== void 0 ? _c : defaults.deelClient.waarde }) });
}
exports.mapWoning = mapWoning;
