"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MappingUtils = void 0;
var MappingUtils;
(function (MappingUtils) {
    function checkDefaults(defaults, source, description = '') {
        if (!defaults)
            throw Error(`${description} missing defaults`);
        if (!source)
            throw Error(`${description} missing source data`);
        if (defaults.length !== source.length)
            throw Error(`${description} defaults and source data mismatch`);
        return true;
    }
    MappingUtils.checkDefaults = checkDefaults;
})(MappingUtils = exports.MappingUtils || (exports.MappingUtils = {}));
