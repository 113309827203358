"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaults = exports.getPersonenForDefaultsRequest = exports.getOnderwerpenForDefaultsRequest = exports.ONDERWERP_DEFAULTS = void 0;
const date_1 = require("../utils/date");
const map_pensioenen_1 = require("./onderwerpen/map-pensioenen");
const onderwerpen_1 = require("./onderwerpen");
const aqopi_data_combine_1 = require("@iwize-aqopi/aqopi-data-combine");
const separate_combined_data_1 = require("../utils/separate-combined-data");
const getValues_1 = require("../utils/getValues");
const map_persoonlijke_leningen_1 = require("./onderwerpen/map-persoonlijke-leningen");
const map_doorlopendkrediet_1 = require("./onderwerpen/map-doorlopendkrediet");
const map_direct_ingaande_lijfrente_1 = require("./onderwerpen/map-direct-ingaande-lijfrente");
/**
 * This list contains a set of metadata for each onderwerp supported by the mapping functions
 * Some items are part of a parent container and should be submitted as such to the `Dossier` endpoint.
 */
// Note: The toewijzing-properties are used during actual mapping if there are two aanvragers
exports.ONDERWERP_DEFAULTS = [
    { type: 'Dienstbetrekking', container: undefined, toewijzing: 'Client' },
    { type: 'Pensioenaanspraak', container: undefined, toewijzing: 'Client' },
    { type: 'PensioenUPO', container: undefined, toewijzing: 'Client' },
    { type: 'Woning', container: undefined, toewijzing: 'Samen' },
    { type: 'HypothecaireLening', container: undefined, toewijzing: 'Samen' },
    { type: 'AnnuiteitenHypotheek', container: 'HypothecaireLening', toewijzing: 'Samen' },
    { type: 'SpaarHypotheek', container: 'HypothecaireLening', toewijzing: 'Samen' },
    { type: 'LineaireHypotheek', container: 'HypothecaireLening', toewijzing: 'Samen' },
    { type: 'AflossingsvrijeHypotheek', container: 'HypothecaireLening', toewijzing: 'Samen' },
    { type: 'KredietHypotheek', container: 'HypothecaireLening', toewijzing: 'Samen' },
    { type: 'BankspaarHypotheek', container: 'HypothecaireLening', toewijzing: 'Samen' },
    { type: 'BeleggingsHypotheek', container: 'HypothecaireLening', toewijzing: 'Samen' },
    { type: 'LevenHypotheek', container: 'HypothecaireLening', toewijzing: 'Samen' },
    { type: 'SpecifiekTegoed', container: undefined, toewijzing: 'Samen' },
    { type: 'Premiedepot', container: undefined, toewijzing: 'Samen' },
    { type: 'Kapitaalverzekering', container: undefined, toewijzing: 'Samen' },
    { type: 'Overlijdensrisicoverzekering', container: undefined, toewijzing: 'Client' },
    { type: 'Lijfrenteverzekering', container: undefined, toewijzing: 'Client' },
    { type: 'BancaireLijfrente', container: undefined, toewijzing: 'Client' },
    { type: 'DirectIngaandeLijfrente', container: undefined, toewijzing: 'Client' },
    { type: 'OntvangenAlimentatie', container: undefined, toewijzing: 'Client' },
    { type: 'TeBetalenAlimentatie', container: undefined, toewijzing: 'Client' },
    { type: 'GroeneBeleggingen ', container: undefined, toewijzing: 'Client' },
    { type: 'PersoonlijkeLening', container: undefined, toewijzing: 'Samen' },
    { type: 'Lening', container: undefined, toewijzing: 'Samen' },
    { type: 'DoorlopendKrediet', container: undefined, toewijzing: 'Samen' },
    { type: 'IBOnderneming', container: undefined, toewijzing: 'Client' },
    { type: 'Ondernemersaftrek', container: undefined, toewijzing: 'Client' },
    { type: 'Oudedagsreserve', container: undefined, toewijzing: 'Client' },
];
function getOnderwerpenForDefaultsRequest(summary, partnerSummary) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    let combinedResult = aqopi_data_combine_1.AqopiCombine.combine(JSON.parse(JSON.stringify(summary)), partnerSummary && JSON.parse(JSON.stringify(partnerSummary)));
    const combinedData = (0, separate_combined_data_1.separateCombinedData)(combinedResult);
    const { aanvrager1, aanvrager2 } = combinedData;
    const onderwerpenDefaults = [];
    const clientWerkgeversNum = (_d = (_c = (_b = (_a = aanvrager1.inkomens) === null || _a === void 0 ? void 0 : _a.werkgevers) === null || _b === void 0 ? void 0 : _b.val) === null || _c === void 0 ? void 0 : _c.filter(({ indIsHuidigeWerkgever }) => indIsHuidigeWerkgever).length) !== null && _d !== void 0 ? _d : 0;
    const partnerWerkgeversNum = (_h = (_g = (_f = (_e = aanvrager2 === null || aanvrager2 === void 0 ? void 0 : aanvrager2.inkomens) === null || _e === void 0 ? void 0 : _e.werkgevers) === null || _f === void 0 ? void 0 : _f.val) === null || _g === void 0 ? void 0 : _g.filter(({ indIsHuidigeWerkgever }) => indIsHuidigeWerkgever).length) !== null && _h !== void 0 ? _h : 0;
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('Dienstbetrekking', clientWerkgeversNum, partnerWerkgeversNum));
    const bankrekeningNumData = (0, getValues_1.getValueArrayLengths)(combinedData, 'bezittingen.bankrekeningen.val', 'gezamenlijkeBankrekeningen');
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('SpecifiekTegoed', bankrekeningNumData.aanvrager1, bankrekeningNumData.aanvrager2, bankrekeningNumData.combined));
    const onroerendGoedNumData = (0, getValues_1.getValueArrayLengths)(combinedData, 'bezittingen.onroerendGoed.val', 'gezamenlijkOnroerendGoed');
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('Woning', onroerendGoedNumData.aanvrager1, onroerendGoedNumData.aanvrager2, onroerendGoedNumData.combined));
    const premiedepotNumData = (0, getValues_1.getValueArrayLengths)(combinedData, 'bezittingen.premiedepots.val', 'gezamenlijkePremiedepots');
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('Premiedepot', premiedepotNumData.aanvrager1, premiedepotNumData.aanvrager2, premiedepotNumData.combined));
    const kapitaalVerzekeringenNumData = (0, getValues_1.getValueArrayLengths)(combinedData, 'bezittingen.kapitaalverzekeringen.val', 'gezamenlijkeKapitaalverzekeringen');
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('Kapitaalverzekering', kapitaalVerzekeringenNumData.aanvrager1, kapitaalVerzekeringenNumData.aanvrager2, kapitaalVerzekeringenNumData.combined));
    const clientHasStudyDebt = !!((_k = (_j = aanvrager1.schulden) === null || _j === void 0 ? void 0 : _j.studieschuld) === null || _k === void 0 ? void 0 : _k.val.studieschuld);
    const partnerHasStudyDebt = !!((_m = (_l = aanvrager2 === null || aanvrager2 === void 0 ? void 0 : aanvrager2.schulden) === null || _l === void 0 ? void 0 : _l.studieschuld) === null || _m === void 0 ? void 0 : _m.val.studieschuld);
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('Lening', +clientHasStudyDebt, +partnerHasStudyDebt));
    const clientHasLijfrenteverzekeringen = !!((_p = (_o = aanvrager1.betaaldePremies) === null || _o === void 0 ? void 0 : _o.lijfrente) === null || _p === void 0 ? void 0 : _p.val.length);
    const partnerHasLijfrenteverzekeringen = !!((_r = (_q = aanvrager2 === null || aanvrager2 === void 0 ? void 0 : aanvrager2.betaaldePremies) === null || _q === void 0 ? void 0 : _q.lijfrente) === null || _r === void 0 ? void 0 : _r.val.length);
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('Lijfrenteverzekering', +clientHasLijfrenteverzekeringen, +partnerHasLijfrenteverzekeringen));
    const clientHasDirectIngaandeLijfrente = !!((_v = (_u = (_t = (_s = aanvrager1.inkomens) === null || _s === void 0 ? void 0 : _s.belastingaangifte) === null || _t === void 0 ? void 0 : _t.inkomens) === null || _u === void 0 ? void 0 : _u.val) !== null && _v !== void 0 ? _v : []).some(map_direct_ingaande_lijfrente_1.isDirectIngaandeLijfrente);
    const partnerHasDirectIngaandeLijfrente = !!((_z = (_y = (_x = (_w = aanvrager2 === null || aanvrager2 === void 0 ? void 0 : aanvrager2.inkomens) === null || _w === void 0 ? void 0 : _w.belastingaangifte) === null || _x === void 0 ? void 0 : _x.inkomens) === null || _y === void 0 ? void 0 : _y.val) !== null && _z !== void 0 ? _z : []).some(map_direct_ingaande_lijfrente_1.isDirectIngaandeLijfrente);
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('DirectIngaandeLijfrente', +clientHasDirectIngaandeLijfrente, +partnerHasDirectIngaandeLijfrente));
    const overigeSchuldenData = (0, getValues_1.getValueArrays)(combinedData, 'schulden.overigeSchulden.val', 'gezamenlijkeOverigeSchulden');
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('PersoonlijkeLening', overigeSchuldenData.aanvrager1.filter(map_persoonlijke_leningen_1.isPersoonlijkeLening).length, overigeSchuldenData.aanvrager2.filter(map_persoonlijke_leningen_1.isPersoonlijkeLening).length, overigeSchuldenData.combined.filter(map_persoonlijke_leningen_1.isPersoonlijkeLening).length));
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('DoorlopendKrediet', overigeSchuldenData.aanvrager1.filter(map_doorlopendkrediet_1.isDoorlopendKrediet).length, overigeSchuldenData.aanvrager2.filter(map_doorlopendkrediet_1.isDoorlopendKrediet).length, overigeSchuldenData.combined.filter(map_doorlopendkrediet_1.isDoorlopendKrediet).length));
    const hypotheekLeningData = (0, getValues_1.getValueArrays)(combinedData, 'schulden.hypotheekdelen.val', 'gezamenlijkeHypotheekdelen');
    const clientHypotheekLeningenGroupNum = getMappedHypothecaireLeningenNum(hypotheekLeningData.aanvrager1);
    const partnerHypotheekLeningenGroupNum = getMappedHypothecaireLeningenNum(hypotheekLeningData.aanvrager2);
    const coupleHypotheekLeningenGroupNum = getMappedHypothecaireLeningenNum(hypotheekLeningData.combined);
    if (clientHypotheekLeningenGroupNum + partnerHypotheekLeningenGroupNum + coupleHypotheekLeningenGroupNum > 0) {
        onderwerpenDefaults.push(...getMappedOnderwerpDefaults('HypothecaireLening', clientHypotheekLeningenGroupNum, partnerHypotheekLeningenGroupNum, coupleHypotheekLeningenGroupNum));
        const clientMortgageParts = hypotheekLeningData.aanvrager1;
        const partnerMortgageParts = hypotheekLeningData.aanvrager2;
        const coupleMortgageParts = hypotheekLeningData.combined;
        onderwerpenDefaults.push(...getMappedHypothecaireLeningdelen(clientMortgageParts, partnerMortgageParts, coupleMortgageParts));
    }
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('PensioenUPO', +(0, map_pensioenen_1.hasLivePensions)(summary), +(!!partnerSummary && (0, map_pensioenen_1.hasLivePensions)(partnerSummary))));
    onderwerpenDefaults.push(...getMappedOnderwerpDefaults('Pensioenaanspraak', +(0, map_pensioenen_1.hasOldPensions)(summary), +(!!partnerSummary && (0, map_pensioenen_1.hasOldPensions)(partnerSummary))));
    return onderwerpenDefaults;
}
exports.getOnderwerpenForDefaultsRequest = getOnderwerpenForDefaultsRequest;
function getPersonenForDefaultsRequest(summary, partnerSummary) {
    var _a, _b, _c, _d, _e, _f;
    const personen = [];
    const geboortedatum = (_c = (_b = (_a = summary.persoonsgegevens) === null || _a === void 0 ? void 0 : _a.aanvrager) === null || _b === void 0 ? void 0 : _b.geboortedatum) === null || _c === void 0 ? void 0 : _c.val;
    if (!geboortedatum)
        throw Error('Geboorte datum is verplicht');
    personen.push({
        clientPartner: 'Client',
        geboortedatum: date_1.DateUtils.fromAqopiDateRequired(geboortedatum)
    });
    if (partnerSummary) {
        const partnerGeboorteDatum = (_f = (_e = (_d = partnerSummary.persoonsgegevens) === null || _d === void 0 ? void 0 : _d.aanvrager) === null || _e === void 0 ? void 0 : _e.geboortedatum) === null || _f === void 0 ? void 0 : _f.val;
        if (partnerGeboorteDatum) {
            personen.push({
                clientPartner: 'Partner',
                geboortedatum: date_1.DateUtils.fromAqopiDateRequired(partnerGeboorteDatum)
            });
        }
    }
    return personen;
}
exports.getPersonenForDefaultsRequest = getPersonenForDefaultsRequest;
function getDefaults(api, request, personen) {
    return __awaiter(this, void 0, void 0, function* () {
        const defaults = yield api.defaults(request, personen);
        return defaults;
    });
}
exports.getDefaults = getDefaults;
function getMappedHypothecaireLeningdelen(clientMortgageParts, partnerMortgageParts, coupleMortgageParts) {
    return [
        ...getMappedLeningdelenVoorAanvrager(clientMortgageParts, 'Client'),
        ...getMappedLeningdelenVoorAanvrager(partnerMortgageParts, 'Partner'),
        ...getMappedLeningdelenVoorAanvrager(coupleMortgageParts, 'Samen'),
    ];
}
function getMappedLeningdelenVoorAanvrager(mortgageParts, toewijzing) {
    return mortgageParts
        .map((mortgagePart) => onderwerpen_1.aqopiToOmniPlanOnderwerpMap[mortgagePart.soortHypotheekAfgeleid])
        .filter((mappedMortgagePart) => !!mappedMortgagePart)
        .map((type) => getOnderwerpDefaults(type, toewijzing))
        .filter((defaults) => !!defaults);
}
function getMappedHypothecaireLeningenNum(mortgageParts) {
    if (!mortgageParts) {
        return 0;
    }
    const mortgageGroups = new Set();
    mortgageParts.forEach((mortgagePart) => {
        var _a;
        const id = ((_a = mortgagePart.hdnMijCode) !== null && _a !== void 0 ? _a : '') + mortgagePart.begindatumBerekend;
        mortgageGroups.add(id);
    });
    return mortgageGroups.size;
}
function getOnderwerpDefaults(onderwerpType, toewijzing) {
    const onderwerp = exports.ONDERWERP_DEFAULTS.find(onderwerp => onderwerp.type === onderwerpType);
    if (!onderwerp) {
        return;
    }
    return Object.assign(Object.assign({}, onderwerp), { toewijzing });
}
function getMappedOnderwerpDefaults(type, clientCount, partnerCount = 0, coupleCount = 0) {
    const clientOnderwerpDefault = getOnderwerpDefaults(type, 'Client');
    const partnerOnderwerpDefault = getOnderwerpDefaults(type, 'Partner');
    const coupleOnderwerpDefault = getOnderwerpDefaults(type, 'Samen');
    if (clientOnderwerpDefault || partnerOnderwerpDefault || coupleOnderwerpDefault) {
        const onderwerpDefaults = new Array(clientCount + partnerCount + coupleCount);
        onderwerpDefaults.fill(clientOnderwerpDefault, 0, clientCount);
        onderwerpDefaults.fill(partnerOnderwerpDefault, clientCount, clientCount + partnerCount);
        onderwerpDefaults.fill(coupleOnderwerpDefault, clientCount + partnerCount, clientCount + partnerCount + coupleCount);
        return onderwerpDefaults;
    }
    else {
        return [];
    }
}
