"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTaxTermEndDate = exports.getTaxTermEndDateCollection = exports.getAowDates = exports.getToewijzing = void 0;
const date_1 = require("../utils/date");
function getToewijzing(property, clientID) {
    if (!property.relatedTo || !property.relatedTo.length) {
        return 'Client';
    }
    if (property.relatedTo.length > 1) {
        return 'Samen';
    }
    if (property.relatedTo[0] === clientID) {
        return 'Client';
    }
    return 'Partner';
}
exports.getToewijzing = getToewijzing;
function getAowDates(summary, partnerSummary) {
    var _a, _b, _c, _d;
    return {
        client: date_1.DateUtils.fromAqopiDate((_b = (_a = summary.pensioenen) === null || _a === void 0 ? void 0 : _a.algemeen) === null || _b === void 0 ? void 0 : _b.val.aowDatum),
        partner: date_1.DateUtils.fromAqopiDate((_d = (_c = partnerSummary === null || partnerSummary === void 0 ? void 0 : partnerSummary.pensioenen) === null || _c === void 0 ? void 0 : _c.algemeen) === null || _d === void 0 ? void 0 : _d.val.aowDatum),
    };
}
exports.getAowDates = getAowDates;
function getTaxTermEndDateCollection(summary, partnerSummary) {
    var _a, _b, _c, _d;
    const aanvrager1TaxTermEndDate = ((_a = summary.schulden) === null || _a === void 0 ? void 0 : _a.hypotheekdelen)
        ? getTaxTermEndDate((_b = summary.schulden) === null || _b === void 0 ? void 0 : _b.hypotheekdelen)
        : undefined;
    const aanvrager2TaxTermEndDate = ((_c = partnerSummary === null || partnerSummary === void 0 ? void 0 : partnerSummary.schulden) === null || _c === void 0 ? void 0 : _c.hypotheekdelen)
        ? getTaxTermEndDate((_d = partnerSummary === null || partnerSummary === void 0 ? void 0 : partnerSummary.schulden) === null || _d === void 0 ? void 0 : _d.hypotheekdelen)
        : undefined;
    let combinedTaxTermEndDate;
    if (aanvrager1TaxTermEndDate && aanvrager2TaxTermEndDate) {
        combinedTaxTermEndDate = aanvrager1TaxTermEndDate > aanvrager2TaxTermEndDate
            ? aanvrager1TaxTermEndDate
            : aanvrager2TaxTermEndDate;
    }
    else {
        combinedTaxTermEndDate = aanvrager1TaxTermEndDate || aanvrager2TaxTermEndDate;
    }
    return {
        Client: aanvrager1TaxTermEndDate,
        Partner: aanvrager2TaxTermEndDate,
        Samen: combinedTaxTermEndDate
    };
}
exports.getTaxTermEndDateCollection = getTaxTermEndDateCollection;
function getTaxTermEndDate(summaryProperty) {
    // Date before March 1st means data is from 2 years ago, else 1 year ago
    const date = date_1.DateUtils.fromAqopiDate(summaryProperty === null || summaryProperty === void 0 ? void 0 : summaryProperty.meta.datum);
    date.setMonth(date.getMonth() - 2);
    const taxYear = date.getFullYear() - 1;
    return new Date(`${taxYear}-12-31`);
}
exports.getTaxTermEndDate = getTaxTermEndDate;
