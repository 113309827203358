"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapChild = exports.mapChildren = void 0;
const types_1 = require("../types");
const date_1 = require("../utils/date");
function mapChildren(summary, partnerSummary) {
    var _a, _b, _c, _d;
    const children = [];
    const summaryChildren = (_b = (_a = summary.persoonsgegevens) === null || _a === void 0 ? void 0 : _a.kinderen) === null || _b === void 0 ? void 0 : _b.val;
    const partnerSummaryChildren = (_d = (_c = partnerSummary === null || partnerSummary === void 0 ? void 0 : partnerSummary.persoonsgegevens) === null || _c === void 0 ? void 0 : _c.kinderen) === null || _d === void 0 ? void 0 : _d.val;
    const joinedChildren = summaryChildren && partnerSummaryChildren
        ? summaryChildren.filter(v => partnerSummaryChildren.find(w => isSameChild(v, w)))
        : undefined;
    if (joinedChildren) {
        children.push(...joinedChildren.map((v, x) => mapChild(v, types_1.Omniplan.Ouder.Samen)));
    }
    if (summaryChildren) {
        children.push(...summaryChildren
            .filter(v => !(joinedChildren === null || joinedChildren === void 0 ? void 0 : joinedChildren.find(w => isSameChild(v, w))))
            .map(v => mapChild(v, types_1.Omniplan.Ouder.Client)));
    }
    if (partnerSummaryChildren) {
        children.push(...partnerSummaryChildren
            .filter(v => !(joinedChildren === null || joinedChildren === void 0 ? void 0 : joinedChildren.find(w => isSameChild(v, w))))
            .map(v => mapChild(v, types_1.Omniplan.Ouder.Partner)));
    }
    return children;
}
exports.mapChildren = mapChildren;
function mapChild(val, parent) {
    if (!val.geslachtsnaam)
        throw Error('achternaam');
    if (!val.geboortedatum)
        throw Error('geboortedatum');
    const geboortedatum = date_1.DateUtils.toDateTimeString(date_1.DateUtils.fromAqopiDate(val.geboortedatum));
    if (!geboortedatum)
        throw Error('geboortedatum');
    const child = {
        voornaam: val.voornamen,
        voorletters: val.initialen,
        tussenvoegsels: val.voorvoegselsGeslachtsnaam,
        achternaam: val.geslachtsnaam,
        geboortedatum: geboortedatum,
        geboorteplaats: val.geboorteplaats,
        geslacht: types_1.Omniplan.Geslacht.Onbekend,
        ouder: parent,
    };
    return child;
}
exports.mapChild = mapChild;
function isSameChild(a, b) {
    return (a.geboortedatum === b.geboortedatum) && (a.voornamen === b.voornamen);
}
