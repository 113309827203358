"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapSpecifiekTegoed = exports.mapSpecifiekTegoeden = void 0;
const aqopi_types_1 = require("@iwize-aqopi/aqopi-types");
const iban_to_maatschappij_1 = require("../../utils/iban-to-maatschappij");
const map_utils_1 = require("../map-utils");
const mapping_1 = require("../../utils/mapping");
const date_1 = require("../../utils/date");
function mapSpecifiekTegoeden(defaults, combinedData) {
    const bankrekeningen = combinedData.bezittingen.bankrekeningen;
    const specifiekeTegoeden = [];
    if (!bankrekeningen || !bankrekeningen.all.length) {
        return [];
    }
    const clientSnapshotDate = (0, map_utils_1.getTaxTermEndDate)(combinedData.aanvrager1.bezittingen.bankrekeningen);
    const partnerSnapshotDate = (0, map_utils_1.getTaxTermEndDate)(combinedData.aanvrager1.bezittingen.bankrekeningen);
    const combinedSnapshotDate = clientSnapshotDate && partnerSnapshotDate && partnerSnapshotDate > clientSnapshotDate ? partnerSnapshotDate : clientSnapshotDate;
    if (mapping_1.MappingUtils.checkDefaults(defaults.client, bankrekeningen.aanvrager1, 'bankrekeningen')) {
        specifiekeTegoeden.push(...bankrekeningen.aanvrager1.map((bankrekening, idx) => mapSpecifiekTegoed(bankrekening, defaults.client[idx], clientSnapshotDate)));
    }
    if (mapping_1.MappingUtils.checkDefaults(defaults.partner, bankrekeningen.aanvrager2, 'bankrekeningen')) {
        specifiekeTegoeden.push(...bankrekeningen.aanvrager2.map((bankrekening, idx) => mapSpecifiekTegoed(bankrekening, defaults.partner[idx], partnerSnapshotDate)));
    }
    if (mapping_1.MappingUtils.checkDefaults(defaults.couple, bankrekeningen.combined, 'bankrekeningen')) {
        specifiekeTegoeden.push(...bankrekeningen.combined.map((bankrekening, idx) => mapSpecifiekTegoed(bankrekening, defaults.couple[idx], combinedSnapshotDate)));
    }
    return specifiekeTegoeden;
}
exports.mapSpecifiekTegoeden = mapSpecifiekTegoeden;
function mapSpecifiekTegoed(summaryPart, defaults, snapshotDate) {
    var _a, _b;
    const tegoed = Object.assign(Object.assign({}, defaults), { naam: (_a = summaryPart.omschrijving) !== null && _a !== void 0 ? _a : defaults.naam, soort: mapBankAccountType(summaryPart.type), rekeningnummer: summaryPart.rekNr, saldo: Object.assign(Object.assign({}, ((_b = defaults.saldo) !== null && _b !== void 0 ? _b : {})), { waarde: summaryPart.waarde }), saldoPer: snapshotDate ? date_1.DateUtils.toDateTimeString(snapshotDate) : defaults.saldoPer, maatschappij: (0, iban_to_maatschappij_1.getMaatschapijForIBAN)(summaryPart.rekNr) });
    if (summaryPart.type === aqopi_types_1.Aqopi.Summary.BankAccountType.EffectenRekening) {
        tegoed.koersstijging = { waarde: 0 };
    }
    return tegoed;
}
exports.mapSpecifiekTegoed = mapSpecifiekTegoed;
function mapBankAccountType(val) {
    switch (val) {
        case aqopi_types_1.Aqopi.Summary.BankAccountType.BankRekening:
            return 'Spaargeld';
        case aqopi_types_1.Aqopi.Summary.BankAccountType.EffectenRekening:
            return 'Beleggingen';
        default:
            throw Error('onbekend idDocType');
    }
}
